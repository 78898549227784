
export default {
    state:'table',
    table:{
        columns:{
            _check:{},
            _id:{},
            Producto:{
                columns:{
                    _image:{},
                    Nombre:{
                        rows:{
                            nombre:{
                                columns:{
                                    nombre:{
                                        className:'font-medium text-md',
                                    },
                                    'presentaciones.presentacion':{
                                        className:' bg-gray-400 rounded px-1 text-sm !text-white'
                                    }
                                }
                            },
                            'marcas.marca':{
                                className:' !text-gray-400'
                            },
                        }
                    },
                }
            },
            Funcion:{
                rows:{
                    'funciones.funcion':{
                        className:'',
                    },
                    'especialidades':{},
                }
            },

            Existencia:{
                className:'items-center',
                rows:{
                    'existencia':{
                        className:'!text-center font-bold',
                        type:'existencia',
                    },
                    Stocks:{
                        columns:{
                            'stock_minimo':{
                                icon:"down",
                                className:'!text-gray-400'
                            },
                            'stock_maximo':{
                                icon:"up",
                                className:'!text-gray-400',
                            },
        
                        }
                    }
                }
            },
            ultimo_costo:{
                label:'Costo',
                //type:'hidden',
            },
            _update:{},
            _delete:{},
            _status:{},
        }
    },
}