import useTheme from '../../context/useTheme'
import Button from '../Button'
import useData from '../../context/useData'
import setError from '../../context/setError'
import { event } from '../../libs/utils'

const useTw = () => useTheme(theme => ({
    root:`
    `,
    buttons:`
        p-4
        flex justify-between
    `,
}), "tw")

export default function Footer(props) {

    const tw = useTw()
    const { schema, id, footer } = props
    const { name, data, rows, saveButton=schema.editable, cancelButton=schema.editable, events, api } = schema
    const [ edit=false, setEdit ] = useData(`${name}/form/data/__editing`, 'Footer')

    const { label="Cancelar" } = typeof cancelButton === 'object' ? cancelButton : {}

    return(
        <div className={tw.root}>
            <div className={tw.buttons}>
                {[
                    cancelButton && <Button key="cancel" type="cancel" label={label} onClick={onCancel} />,
                    (saveButton && edit) && <Button key="accept" label="Guardar" onClick={onClick} />
                ]}
            </div>
            { footer &&
                <div>
                    {footer}
                </div>
            }
        </div>
    )

    function onCancel() {

        const { afterCancel } = events
        const { onClick } = typeof cancelButton === 'object' ? cancelButton : {} 

        console.log("onCancel", {afterCancel, onClick})

        event(afterCancel, props)
        event(onClick, props)
            || events.addLocation('/..')
    }

    function onClick() {

        const { afterSave, afterInsert, afterUpdate } = events
        const { onClick } = typeof cancelButton === 'object' ? cancelButton : {} 

        

        if(id > 0) {

            api.put(data, res => {

                setEdit(false)

                event(afterSave, props, res)
                event(afterUpdate, props, res)
                event(onClick, props, res)

            }, setError)

        } else {

            api.post(getPostData(), res => {

                event(afterSave, props, res)
                event(afterUpdate, props, res)
                event(onClick, props, res)
                    || events.addLocation(`/../${res._id}`)

            }, setError)

        }

        function getPostData(){

            const post = data
            delete post.__editing

            Object.entries(rows).forEach(([key, props]) => {
              
                if(props.def !== undefined && post[key] === undefined) {
                    post[key] = props.def
                }
            })

            return post
        }

    }
}