import app from "../../data/app"
import { getToken } from "./storage"
import { getLocation, setLocation } from "./location"

export default function usePath() {

    const {routes, authentication={}, index} = app
    const {protocol, host, pathName, params, url, pathList, route} = getLocation()

    const [target, authenticate, strPath, targetParams, name] = getTarget(pathList, routes, authentication)

    const token = getToken()
    var ready = true

    if(!target && app.routes[index]) {
        console.log(index, {token, target, authenticate, strPath, targetParams, name})
        //alert(1)
        setPath(index)

    } else if(authenticate && !token) {

        setPath(authentication.route)
    }

    return { ready, protocol, host, pathName, pathList, route, target, targetParams, name, src, params, setPath }

    function src(file) {
        return strPath+file
    }

    function setPath(path) {

        if(path !== pathName.substring(1)) {
            ready = false
            //document.location.href = `${url}/${path}`
            setLocation(path)
        }
    }
}

function getTarget(pathList, target, authentication) {

    const params = []
    var name
    var strPath = ""
    pathList.forEach(path => {
        strPath += "../"

        if(target && target[path]) {

            target = target[path].routes || target[path]
            name = path

        } else {
            if(name) {
                params.push(path)
            } else
                target = false
        }
    })

    const authenticate = (target && target.authenticate !== undefined)
        ? target.authenticate
        : authentication.required

    return [target, authenticate, strPath, params, name]
}