import { useState } from "react"
import { UserIcon } from "@heroicons/react/24/solid"
import { UserIcon as UserOutlinedIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon, CheckIcon, PencilIcon } from "@heroicons/react/24/outline"
import useTheme from "../../vio/context/useTheme"
import useGet from "../../vio/libs/useGet"
import Modal from "../../vio/components/modal"
import useRest from "../../vio/libs/useRest"
import { dateToString } from "../../vio/libs/utils"
import { getStorage } from "../../vio/libs/storage"

// Icono Left al ir al siguiente mes
// Fecha limite de cancelacion

const useTw = () => useTheme(theme => ({
    root:`
        relative
        flex flex-col justify-center items-center
        min-w-full
        bg-white
        rounded
        shadow
    `,
    captionWeek:{
        root:`
            flex justify-between items-center
            px-1
        `,
        icon:`
            w-12 h-12
            px-2
            bg-white
            text-black
            dark:bg-slate-700
            rounded
            cursor-default
        `,
        disabled:`
            text-white/10
        `,
        label:`
            flex grow justify-center
            py-3 mx-1
            //bg-slate-700
            rounded
        `,
        itemLabel:`
            flex
            px-8
        `
    },
    calendar:{
        root:`
            min-w-full
            border-separate
            border-spacing-1
            //bg-white
            //shadow
            rounded
        `,
        weekDay:`
            relative
            py-2
            bg-cyan-600
            text-white
            dark:bg-white/20
            w-1/12
            rounded
            font-normal
            peer
            shadow
        `,
        dayLabel:`
            text-cyan-200
            text-xl
        `,
        dateLabel:`
            font-thin
        `,
        rowLabel:`
            flex justify-end items-center
            px-2 py-2
            //bg-white
            bg-cyan-100
            //bg-cyan-600
            dark:bg-white/20
            rounded
            font-thin
            shadow
        `,
        hourLabel:`
            text-4xl
            //text-white
            text-cyan-500
        `,
        amLabel:`
            text-black
            hover:text-white
            pl-1
        `,
        notAvailable:`
            bg-white
            //bg-gray-100
            dark:bg-white/5
            shadow
        `,
        available:`
            bg-white/10
            bg-white
            dark:bg-white/20
            shadow
        `,
    },
    content:`
        relative
        flex justify-center items-center
        group
    `,
    user:`
        w-6 h-6
        text-slate-500/50
    `,
    selfUser:`
        w-6 h-6
        text-green-500
    `,
    anotherUser:`
        w-6 h-6
        text-gray-500
        hover:text-white
    `,
    modal:{
        caption:`
            pt-8
            text-cyan-400
        `,
        availableLabel:`
            pt-5
            text-md
            font-thin
            text-slate-400
        `,
        description:`
            text-white
        `,
        number: {
            root:`
                mr-2 p-2 px-4
                border rounded-md
                bg-black
                cursor-default
                hover:border-cyan-400
            `,
            selected:`
                border-cyan-400
                bg-cyan-400
                text-black
                font-bold
            `,
             disabled:`
                text-white/50
                border-0
             `
        },
        error:`
            p-1 px-2
            text-white
            bg-red-500
            rounded
        `,
        reservatedNumber:`
            text-cyan-400
            pl-2
        `,
        deleteButtonContainer:`
            pt-10
        `,
        deleteButton:`
            p-1 px-2
            bg-red-600
            hover:bg-red-500
            rounded
            cursor-default
        `,
        captionReservation:`
            pt-4
            text-sm
        `
    },
    lockDayIcon:`
        absolute top-0 right-0
        w-8 h-8
        text-gray-500
        //visible
    `,
    lockHourIcon:`
        absolute top-0 right-0
        w-8 h-8
        text-gray-500
        //visible
    `,
    pencilIcon:`
        absolute top-0 right-1
        w-7 h-7
        text-amber-500
        //visible
    `,
    checkIcon:`
        absolute top-0 right-0
        w-7 h-7
        text-gray-500
        hover:text-white
        //visible
    `,
    lock:`
        flex justify-center
        min-w-full min-h-full
        bg-red-900/40
        text-white
        hover:text-white/25
    `,
    detail:{
        item:`
            flex justify-between
            text-white
            py-4
        `,
        icons:`
            flex
            mr-10
        `,
        name:`
            ml-4
        `,
        user:`
            mr-4
            w-6 h-6
            text-green-500
        `,
        checkedUser:`
            text-cyan-500
        `,
        check:`
            ml-8
            w-7 h-7
            text-green-500
        `,
        cancel:`
            ml-8
            w-7 h-7
            text-red-500
        `,
    },
}), "tw")

export default function Agenda({}) {

    const tw = useTw()
    const [api, {success, data}] = useGet("reservaciones", {})
    const [api2] = useRest("reservaciones", {})
    const [reservacion, setReservacion] = useState({open:false})
    const [detail, setDetail] = useState({open:false})
    const {agenda, now, beforeWeek, monday, nextWeek, integrantes, aforo} = data || {}
    const { nivel } = getStorage("user", true)

    const isAdmin = nivel === 'admin'
    const isAsistencia = nivel === 'asistencia'

    //console.log(data) next

    return ( success &&
        <div className={tw.root}>
            <ModalReservacion/>
            <ModalDetail/>
            <table className={tw.calendar.root}>
                <TopCalendar/>
                <Header/>
                <Body/>
            </table>
            <a href='http://www.dicet.org/club-de-natacioacuten-dicet-ac.html' target="_blank">Ver reglamento de ingreso</a>
        </div>
    )

    function TopCalendar() {

        return (
            <caption>
                <div className={tw.captionWeek.root}>
                    <ChevronLeftIcon className={tw.captionWeek.icon + (beforeWeek ? '' : tw.captionWeek.disabled)} onClick={ beforeWeek ? before : null}/>
                    <div className={tw.captionWeek.label}>
                        <span key={0} className={tw.captionWeek.itemLabel}>
                            <UserOutlinedIcon className={tw.user + " text-slate-500"}/> Disponible
                        </span>
                        <span key={1} className={tw.captionWeek.itemLabel}>
                            <UserIcon className={tw.anotherUser}/> Ocupado
                        </span>
                        <span key={2} className={tw.captionWeek.itemLabel}>
                            <UserIcon className={tw.selfUser}/> Tus Reservaciones
                        </span>
                    </div>
                    <ChevronRightIcon className={tw.captionWeek.icon} onClick={() => next()}/>
                </div>
            </caption>
        )

        function next() {

            api.get({date:nextWeek})
            
        }

        function before() {
            api.get({date:beforeWeek})
        }
    }

    function Header() {

        return(
            <thead>
                <tr>
                    <th key="label" style={{width:'0%'}} className={tw.calendar.weekDay} > </th>
                    {agenda.map(({dia, fecha}) =>
                        <th key={dia} className={tw.calendar.weekDay} style={{width: 100 / agenda.length + "%"}}> 
                            <div key="day" className={tw.calendar.dayLabel}>
                                {dia}
                            </div>
                            <div key="date" className={tw.calendar.dateLabel}>
                                {dateToString(fecha)}
                            </div>
                            { isAdmin && <XMarkIcon key={fecha} className={tw.lockDayIcon}
                                onClick={e => lock(e, fecha)}
                            />
                            }
                        </th>
                    )}
                </tr>
            </thead>
        )
    }

    function Body() {

        return (
            <tbody>
                {getHorario().map(hora =>
                    <tr key={hora}>
                        <RowLabel hora={hora}/>
                        {agenda.map((props, ind) =>
                            <CellHour key={ind} {...{...props, hora}}/>
                        )}
                    </tr>
                )}
            </tbody>
        )
    }

    function CellHour(props) {

        const {fecha, dia, hora, reservaciones} = props
        const rsv = reservaciones && reservaciones[hora] ? reservaciones[hora] : {}
        const {className, onClick, show} = getDataHour({...props, reservaciones:rsv})
        const people = []

        var locked = false, lockedHour = false

        if(reservaciones && reservaciones[0] && reservaciones[0].status === 2)
            locked = true
        
        var res = 0

        if(reservaciones && reservaciones[hora]) {

            const { disponibles, reservados, propios, status } = reservaciones[hora]
            res = reservados

            if(status === 2)
                lockedHour = true

            for(var i=0; i<propios;             i++)    people.push(<UserIcon key={"s"+i} className={tw.selfUser}/>)
            for(var i=0; i<reservados-propios;  i++)    people.push(<UserIcon key={"a"+i} className={tw.anotherUser}/>)
            for(var i=0; i<disponibles;         i++)    people.push(<UserOutlinedIcon key={"p"+i} className={tw.user}/>)

        } else {

            for(var i=0; i<4;                   i++)    people.push(<UserOutlinedIcon key={"u"+i} className={tw.user}/>)
        }

        if(res === 0)
            isAdmin && people.push(<XMarkIcon key={hora} className={tw.lockHourIcon} onClick={e => lock(e, fecha + " " + (hora < 10 ? "0"+hora:hora)+":00:00")}/>)
        else 
            (isAdmin || isAsistencia) && people.push(<PencilIcon key={hora} className={tw.pencilIcon} onClick={e => {
                    setDetail({
                        open:true,
                        fecha,
                        dia,
                        hora
                    })
                    e.stopPropagation()
                }}
            />)

        return (
            <td key={dia} className={className}  onClick={!locked && !lockedHour ? onClick:null}>
                <div className={tw.content}>
                    { locked 
                        ? <div className={tw.lock}>
                            Sin Servicio
                        
                        </div>
                        : lockedHour 
                            ? <div className={tw.lock}>
                                Sin Servicio
                                { isAdmin && <CheckIcon key={hora} className={tw.checkIcon} onClick={e => lock(e, fecha + " " + (hora < 10 ? "0"+hora:hora)+":00:00")}/> }
                            </div>
                            : (show && people)
                    }
                </div>
            </td>
        )
    }

    function ModalDetail() {

        const {fecha, dia, hora, open} = detail
        const [error, setError] = useState(undefined)
        const [{get, put, ...apiDet}] = useRest('reservaciones/detail', () => {
            api.get({date:monday})
        }, setError)
        const [data, setData] = useState()

        if(open && data === undefined && error === undefined) {
            get({fecha, hora}, setData, setError)
        }

        return ( open &&
            <Modal
                title={<Title/>}
                body={<Body/>}
                footer={<Error/>}
                cancel={{
                    label: "Cerrar",
                    onClick: onClose
                }}
                accept={{
                    visible: false,
                }}
            />
        )

        function Title() {

            const title = `Editar Horario`

            return(
                <span>
                    <span>
                        { title }
                    </span>
                    <span className={tw.modal.caption}>
                        {null}
                    </span>
                </span>
            )
        }

        function Body(){

            return( data &&
                <span>
                    { data.map(({_id, nombre, accesos, _status}, ind) => {

                        return (
                            <div key={ind} className={tw.detail.item}>
                                <span key="num" className={tw.detail.icons}>
                                    <UserIcon key={"u"+ind} className={tw.detail.user + " " + (_status === 3 ? tw.detail.checkedUser : "")}/>
                                    { accesos }
                                    <span key="name" className={tw.detail.name}>{nombre}</span>
                                </span>
                                <span key="icons" className={tw.detail.icons}>
                                    <CheckIcon className={tw.detail.check} onClick={ e => checkReservation(_id)} />
                                    <XMarkIcon className={tw.detail.cancel} onClick={ e => cancelReservation(_id)} />
                                </span>
                            </div>
                        )
                    })}
                </span>
            )
        }

        function Error() {
            return ( error && 
                <span className={tw.modal.error}>
                    {error}
                </span>
            )
        }

        function checkReservation(id){

            put({id})
        }

        function cancelReservation(id) {

            apiDet.del({id})
        }

        function onClose() {
            setDetail({
                open:false
            })
            setData(undefined)
        }

        function onSuccess(data) {

            setReservacion({
                open:false
            })
            api.get({date:monday})
        }
    }

    function ModalReservacion() {

        const {open, caption, fecha, dia, hora, disponibles=aforo, propios, reservados, reservacion_id} = reservacion
        const [error, setError] = useState(undefined)
        const [{post, del}] = useRest('reservaciones', onSuccess, setError)
        const [accesos, setAccesos] = useState(0)
        const people = integrantes.length

        const description = people === 1
            ? "¿Confirmar reservación?"
            : "¿Cuántas personas ingresarán?"

        return ( open &&
            <Modal
                title={<Title/>}
                body={<Body/>}
                footer={<Error/>}
                cancel={{
                    label: reservacion_id > 0 ? "Cerrar" : "Cancelar",
                    onClick: onClose
                }}
                accept={{
                    onClick: putReservation,
                    visible: reservacion_id > 0 ? false : true,
                }}
            />
        )

        function Title() {

            const title = `${reservacion_id > 0 ? "Reservación":"Reservar"} el ${dia} (${dateToString(fecha)}) a ${ parseInt(caption) === 1 ? "la ":"las " }`

            return(
                <span>
                    <span>
                        { title }
                    </span>
                    <span className={tw.modal.caption}>
                        {caption}
                    </span>
                </span>
            )
        }

        function Body(){

            console.log("BODY", disponibles)
            return(
                <span>
                    {
                        reservacion_id > 0
                            ?   <Reservated/>
                            :   disponibles > 0
                                    ? <Options/>
                                    : <Unavailable/>

                    }
                    {
                        (reservados > 0 && disponibles > 0 && reservacion_id === 0) &&
                        <AvailableNumber/>
                    }
                </span>
            )

            function Reservated() {

                return (
                    <div className={tw.modal.description}>
                        <div >
                            {`Tus accesos reservados en este horario:`}
                            <span className={tw.modal.reservatedNumber}>{propios}</span>
                        </div>
                        <div className={tw.modal.deleteButtonContainer}>
                            <span className={tw.modal.deleteButton} onClick={() => deleteReservation(reservacion_id)}>
                                Cancelar Reservacion
                            </span>
                        </div>
                        <div className={tw.modal.captionReservation}>
                            Para modificar esta reservación puedes CANCELARLA y volver a reservar.
                        </div>
                    </div>
                )
            }

            function Options() {

                return(
                    <>
                        <div className={tw.modal.description}>
                            { description}
                        </div>
                        <div className={tw.modal.caption}>
                            {integrantes.map((el, ind) => {
    
                                const disabled = ind + 1 > disponibles && reservados > 0
    
                                console.log("DIS", disabled, ind, disponibles)
                                const cn = tw.modal.number.root
                                    + (accesos >= ind+1 ? tw.modal.number.selected : "")
                                    + (disabled ? tw.modal.number.disabled : "")
    
                                const onClick = !disabled && (() => setAccesos(ind+1))
    
                                return(
                                    <span key={ind}
                                        className={cn}
                                        onClick={onClick}
                                    >
                                        { ind+1 }
                                    </span>
                                )
                            })}
                        </div>
                    </>
                )
            }

            function Unavailable() {
                return(
                    <div className={tw.modal.description}>
                        No hay accesos disponibles en este horario.
                    </div>
                )
            }

            function AvailableNumber() {

                return(
                    <div className={tw.modal.availableLabel}>
                        { `${disponibles} acceso${disponibles > 1 ? "s":""} disponible${disponibles > 1 ? "s":""}.` }
                    </div>
                )
            }
        }

        function deleteReservation(_id) {

            del(null, _id)
        }

        function putReservation() {
            post({
                fecha,
                hora,
                accesos,
            })
        }

        function Error() {
            return ( error && 
                <span className={tw.modal.error}>
                    {error}
                </span>
            )
        }

        function onClose() {
            setReservacion({
                open:false
            })
        }

        function onSuccess(data) {

            setReservacion({
                open:false
            })
            api.get({date:monday})
        }
    }

    function RowLabel({hora}) {

        const [hr, am] = get12Hours(hora)

        return(
            <td key={-1} className={tw.calendar.rowLabel}>
                <span key="label" className={tw.calendar.hourLabel}>
                    { hr }
                </span>
                <span key="am" className={tw.calendar.amLabel}>
                    { am }
                </span>
            </td>
        )
    }

    function lock(e, fecha) {

        e.stopPropagation()

        api2.put({fecha}, 'lock', res => {
            console.log(res)
            api.get({date:monday})
        })
    }


    function getDataHour({abre, cierra, fecha, hora, dia, reservaciones}) {

        const [hr, am] = get12Hours(hora)
        const dt = fecha + " " + (hora < 10 ? "0"+hora:hora)
        var className = tw.calendar.notAvailable
        var onClick = null
        const show = dt >= now.substring(0, 13) && hora >= abre && hora <= cierra

        //console.log("dt", dt, date.substring(0, 13))

        if(show) {

            className = tw.calendar.available

            onClick = () => {
                setReservacion({
                    open:true,
                    fecha,
                    dia,
                    hora,
                    ...reservaciones,
                    caption: `${hr} ${am}`,
                })
            }
        }

        return { className, onClick, show}
    }

    function get12Hours(hora) {

        return [
            hora < 13 ? hora : hora-12,
            hora < 12 ? " am" : " pm"
        ]
    }

    function getHorario() {

        const horario = []
        var min=24, max=0

        agenda.forEach(({abre, cierra}) => {
            if(abre < cierra) {
                min = abre < min ? abre : min
                max = cierra > max ? cierra : max
            }
        })

        for(var i=min; i <= max; i++)
            horario.push(i)

        return horario
    }
}