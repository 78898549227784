import { ctx } from "../../data/context"

export default function updMetadata(path, value, metadata=false) {

    if(ctx.dispatchersMetadata[path]) {

        const val = ctx.metadata[path] = setMetadata(path, value);

        Object.keys(ctx.dispatchersMetadata[path]).forEach( id =>
            ctx.dispatchersMetadata[path][id](val)
        )

        return val

    } else if(metadata) {

        return setMetadata(path, value)
    }

    return false
}

function setMetadata(path, value=null) {

    var valid = true

    const elem = path.split("/")
    const attr = elem.pop()

    var dat = ctx.schema || {}
    elem.forEach(e => dat = dat[e])

    if(dat && dat[attr]) {
        const {regex, minLength, required} = dat[attr]

        if(regex)
            valid = value.match(regex) === null ? false : true
        else {
            const req = required ? !!value : true
            const len = minLength ? value.length >= minLength : true
            valid = req && len
        }

        return {
            value,
            valid,
        }

    } else if(path === attr) {

        return {
            value:null,
            valid:false,
            ...value,
        }
    }

    return { value, valid:true}
}