import useTheme from "../../vio/context/useTheme"
import Table from "../../vio/components/Table"
import dayjs from "dayjs"

const useTw = () => useTheme(theme => ({
    root:`
        relative
        flex items-center justify-center
        min-w-full h-full
        p-8 pt-16
        dark:bg-black
             bg-slate-200
            //bg-[#ededed]
        dark:text-white
             text-black
        `
}), "tw")

export default function UpdatePassword(props) {

    const tw = useTw()
    //const { mode, target, children, ...pro} = props

    const query={
        where:{
            vencimiento: [">=", dayjs().add(1, 'day').format('YYYY-MM-DD')]
        }
    }

    const map = {
        className:{
            card:`
                min-h-full
                min-w-full
            `
        },
    }

    return (
        <div className={tw.root}>
            <Table name={null} map={map} query={query}/>
        </div>
    )
  }