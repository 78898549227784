import { useState, useEffect } from 'react';

var sizes = {}

function getSize() {

	return (sizes = {
		height: window.innerHeight,
		width: window.innerWidth,
		outerHeight: window.outerHeight,
		outerWidth: window.outerWidth,
	})
}

export default () => {
	let [windowSize, setWindowSize] = useState(getSize());

	function handleResize() {
		setWindowSize(getSize());
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowSize;
}