import useTheme from "../../vio/context/useTheme"

const useTw = () => useTheme(theme => ({
    root:`
        flex justify-center items-center
        overflow-x-hidden
        overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
    `,
}), "tw")

export default function Modal({title, body, footer, cancel={}, accept={}}) {

    const tw = useTw()

    return(
        <>
            <div
                className={tw.root}
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-800 outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200/10 rounded-t">
                    <h3 className="text-xl text-white font-semibold">
                        { title }
                    </h3>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => null}
                    >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                        </span>
                    </button>
                    </div>
                    <div className="my-4 p-5 text-slate-500 text-lg leading-relaxed">
                        { body }
                    </div>
                    { footer &&
                        <div className="relative px-5 flex-auto">
                            <p className="my-2 text-slate-500 text-lg leading-relaxed">
                                {footer}
                            </p>
                        </div>
                    }
                    {/*footer red*/}

                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200/10 rounded-b">
                    { (cancel.visible === undefined || cancel.visible) &&
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={cancel.onClick || null}
                            >
                                {cancel.label || "Cancelar"}
                            </button>
                    }
                    { (accept.visible === undefined || accept.visible) &&
                        <button
                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={accept.onClick || null}
                        >
                            {accept.label || "Aceptar"}
                        </button>
                    }
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}