import { createElement, useState } from "react"
import { ChevronRightIcon, DocumentTextIcon, MenuIcon, XCircleIcon, PowerIcon, XMarkIcon  } from "@heroicons/react/24/outline"
import useTheme from "../../context/useTheme"
import { setLocation } from "../../libs/location"
import { getStorage } from "../../libs/storage"

const useTw = () => useTheme(theme => ({
    root:`
        w-80
    `,
    close:`
        transition-all duration-500 w-16
    `,
    open:`
        transition-all duration-500 w-80
    `,
    container: {
        root: `
            absolute
            w-80 min-h-screen
            bg-slate-600
            shadow-xl
            //z-50
            dark:bg-stone-900
        `,
        content:`
            relative
            flex flex-col justify-end items-end
        `,
        close:`
            transition-all duration-500 -translate-x-64
        `,
        open:`
            transition-all duration-500 translate-x-0
        `,
    },
    icons:{
        close:`
            absolute
            top-4 right-5
            w-7 h-7
            text-slate-400
        `,
        open:`
            transition-rotate duration-1000 rotate-45
        `
    },
    user: {
        root:`
            flex flex-col justify-center items-center
            //pt-8 pb-6
            min-w-full
            border-b
            border-b-white/10
            //bg-blue-500
        `,
        image:`
            w-36 h-36
            rounded-full
            object-cover
            ring-6 ring-blue-500 ring-offset-4 ring-offset-gray-200
        `,
        name:`
            pt-4
            text-white
        `,
    },
    routes:{
        root:`
            //py-4
            text-white
            //bg-red-400
            //w-16
            //overflow-hidden
            item
        `,
        item:{
            root:`
                //pl-2
                cursor-pointer
                relative
            `,
            def:`
                flex items-center
                h-16
                p-2 py-5
                //bg-red-400
                
                min-w-full
                
                //px-5 py-2
                //mb-1
                border-l-4
                border-l-transparent
                text-slate-300
                font-semibold
                uppercase
                text-sm
                hover:bg-white/10
            `,
            icon:`
                absolute
                w-11 h-11
                p-2
                rounded-lg
                //bg-white/10
                //bg-green-400
            `,
            label:`
                min-w-full
                pl-12
            `,
            open:`
                //bg-white/5
                bg-slate-700
                !text-white
                //border-l-4
                //border-l-sky-500
            `,
        },
        subItem:{
            root:`
                relative
                pl-11
                //-mt-1
                mb-4
            `,
            def:`
                flex items-center
                h-10
                px-1.5 py-1.5
                rounded-l-lg
                text-slate-300
                font-normal
                uppercase
                text-sm
                hover:bg-white/10
            `,
            icon:`
                absolute
                w-5 h-5
                //text-stone-400
            `,
            label:`
                pl-8
            `,
            open:`
                //bg-white/20
                //border-l-4
                //border-sky-500
                text-sky-50
                !font-semibold
                !bg-slate-500
            `,
        },
    },
    footer:{
        root:`
            absolute
            bottom-8
            text-slate-400
            flex flex-col justify-center items-center
            text-sm
            min-w-full
            py-4
        `,
        logout:`
            w-8 h-8
            mx-2
            text-red-600
            hover:text-red-400
        `,
    },
    brand:`
        absolute bottom-2 left-16
        text-white
        font-bold
        text-md
        z-50
    `
}), "tw")

export default function Menu({pathName, userPicture, app, routes, src}) {

    const tw = useTw()
    //localStorage.removeItem("menuOpen")
    var isOpen = localStorage.getItem('menuOpen')

    isOpen = isOpen === 'false' || isOpen === false ? false : true

    const [open, setOpen] = useState(isOpen)
    const { components } = app
    //console.log("pathName", pathName)
    const { nombre } = getStorage("user", true)

    localStorage.setItem("menuOpen", open)

    return (
        <div className={tw.root + tw[open ? "open":"close"]}>
            <div className={tw.container.root + tw.container[open ? "open":"close"]}>
            <div className={tw.container.content}>
                <User/>
                <CloseIcon/>
                <Map routes={routes} openMenu={open}/>
            </div>
            <Footer/>
            <a className={tw.brand} href="www.vit.mx">Creado por www.vit.mx</a>
            </div>
            
        </div>
    )

    function Footer() {
        return (
            <div className={tw.footer.root} onClick={logout}>
                <PowerIcon className={tw.footer.logout} />
                CERRAR SESIÓN
            </div>
        )

        function logout() {
            setLocation('logout')
        }
    }
    function User(){

        return(
            <div className={tw.user.root}>
                { userPicture && <img className={tw.user.image} src={src('user2.jpg')} alt="" /> }
                <div className={tw.user.name}>
                    { open ? nombre : "&nbsp;" }
                </div>
            </div>
        )
    }
    function Map({routes, openMenu}){

        return (
            <div className={tw.routes.root + (openMenu ? "min-w-full":"")}>
                <Menu routes={routes} route="/" classItem="item"/>
            </div>
        )

        function Menu({routes, classItem, route}) {


            const twl = tw.routes[classItem]

            return (
                <nav className={twl.root + ( classItem === 'subItem' && !openMenu ? " !pl-5 ":"")}>
                    <ul>
                        {Object.keys(routes).map( it =>
                            <Item key={it} {...{...routes[it], name:it, route:route + it, classItem, twl}} />
                        )}
                    </ul>
                </nav>
            )
        }

        function Item({name, label, route, routes, menu, icon, open, classItem, twl}) {

            const isOpen = route === pathName.substring(0, route.length)

            const [state, setState] = useState(isOpen || open)
            const lIcon = classItem === "item" ? DocumentTextIcon : ChevronRightIcon
            label = label || name.charAt(0).toUpperCase() + name.substring(1)


            if(icon && icon[0]) {
                var component = icon[0]
                var className = icon[1]
            } else {
                var {component, className} = icon || {}
            }

            const isLink = routes ? false : true
            //const isCurrent = 

            return (
                menu !== false &&
                <>
                    <li className={twl.def + (isOpen ? twl.open : "")}
                        //onClick={() => setState(!state)}
                        onClick={ e => isLink ? setLocation(route) : setState(!state)}
                    >
                        {createElement(components[component] || lIcon,{
                                className: twl.icon + " " +(className ? className : "")
                        })}
                        <div className={twl.label}>
                            { openMenu && label }
                        </div>
                    </li>
                    {
                        routes && state &&
                        <div><Menu routes={routes} classItem="subItem" route={route +"/"}/></div>
                    }
                </>
            )
        }
    }



    function CloseIcon() {

        return (
            <XMarkIcon className={tw.icons.close + tw.icons[open ? "": "open"]}
                onClick={ () => setOpen(!open)}
            />
        )
    }
}
