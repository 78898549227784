
export default {
    table:{
        type:"editable",
        search:{
            schema:'productos',
        },
        columns:{
            _id:{},
            Producto:{
                columns:{
                    'productos._image':{},
                    'productos.nombre':{},
                }
            },
            cantidad:{
                edit:true,
            },
            Existencia:{
                className:'items-center',
                rows:{
                    'productos.existencia':{
                        className:'!text-center font-bold',
                        type:'existencia',
                    },
                    Stocks:{
                        columns:{
                            'productos.stock_minimo':{
                                icon:"down",
                                className:'!text-gray-400'
                            },
                            'productos.stock_maximo':{
                                icon:"up",
                                className:'!text-gray-400',
                            },
        
                        }
                    }
                }
            },
            'productos.ultimo_costo':{
                label:'Costo',
            },
        }
    },
    form:{

    },
}

/*
columns:{
    _id:{},
    '/productos': {
        _image:{},
        nombre:{},
        marca:{},
        existencia:{},
        stockMinimo:{},
        stockMaximo:{},
        costo:{},
    },
    cantidad:{},
},*/