import { ctx } from '../../data/context'

export default function setDataContext(path, value, op) {

    var {dat, attr} = {}

    if(path !== undefined) {

        const elem = path.split("/")
        attr = elem.pop()

        dat = ctx.data
        elem.forEach(e => {

            if(dat === undefined || dat[e] === undefined)
                dat[e] = {}

            dat = dat[e]
        })

        switch (op) {
            case "a": // append (default)
                dat[attr] = getState(dat[attr] || value, value)
                break

            case "d": // delete
                delete dat[attr][value]
                dat[attr] = getState(dat[attr] || value)
                break

            case null: // null
                break

            default: // ['r', '*'] = replace
                dat[attr] = getState(value)
                break
        }
    }

    return {path, dat, attr}
}

function getState(state, value) {



    return (
            (Array.isArray(state) && (value !== undefined ? [...state, value] : [...state]))
        ||  (typeof(state) === 'object' && {...state, ...value})
        ||  (typeof(state) === 'boolean'
                ? value 
                : value === undefined ? state : value
            )
    )
}