import { useState, useContext, useEffect, useMemo } from 'react'
import { Context } from './vio/context/Context'
import App from './vio/components/App'
import app from './data/app'
import useRest from './vio/libs/useRest'
import { getToken, delToken } from './vio/libs/storage'
import { mapProperties } from './vio/libs/utils'

export default (props) => {

  const [ctx] = useContext(Context)
  const theme = useMemo(() => null, [ctx.mode, ctx.width, ctx.height])
  const [api] = useRest("authenticate")
  const [ready, setReady] = useState(false)
  const token = getToken()

  useEffect(() => {

    api.get({ token }, ({client}) => {

        mapProperties(app, client)
        setReady(true)

    }, res => {

        delToken('token')
        setReady(true)
    })

  }, [token])

  return (
    ready && <App/>
  )
}

/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/