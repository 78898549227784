import useTheme from '../../vio/context/useTheme'
import app from '../../data/app'
import Menu from '../../vio/components/app/Menu'
import Content from '../../vio/components/app/Content'

const useTw = () => useTheme(theme => ({
    root:`
        flex
        min-w-full min-h-screen
        bg-black
    `,
    content:`
        flex grow
    `,
}), "tw")

export default function Main(props) {

    const {pathName, src, children, mode, app} = props

    const tw = useTw()

    return (
        <div className={tw.root}>
            <Menu pathName={pathName} routes={app.routes} userPicture={app.userPicture} src={src} app={app}/>
            <div className={tw.content}>
                <Content {...props}>
                    { children }
                </Content>
            </div>
        </div>

    )
}