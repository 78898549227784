import { createElement, useState } from 'react'
import { EllipsisVerticalIcon, PlusIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { FilterIcon } from '@heroicons/react/solid'
import useTheme from '../../context/useTheme'
import useData from '../../context/useData'
import { firstUpperCase } from '../../libs/utils'
import Button from '../Button'
import app from '../../../data/app'

const useTw = () => useTheme(theme => ({
    root:`
        relative
        min-h-full
        //px-3 //py-3
        //border
        bg-slate-100
        dark:bg-black/20
        rounded-t
    `,
    container: `
        flex
    `,
    title:{
        root:`
            relative
            flex items-center justify-between
            text-md
        `,
        def:`
            flex justify-center items-center 
            font-bold
            p-4
        `,
    },
    tabs: {
        root:`
            flex
            w-max
            //rounded-lg
        `,
        def: `
            px-3
            //rounded-lg
            text-md
            cursor-default
        `,
        tab:`
            py-2
        `,
        inactive: `
            ////border-b-2
            text-gray-400
            //border-gray-200
            //hover:bg-sky-100
            //hover:border-sky-100
            ////hover:border-b-2
            hover:text-black
        `,
        active:`
            //bg-sky-300
            //border-b-2
            border-sky-400
            //text-white
        `,
    },
    toolsIcons:`
        absolute right-2
        z-10
        flex items-center
    `,
    config:`
        w-6 h-6
        text-gray-400
        //hover:bg-gray-200
        hover:text-black
    `,
    add:`
        h-10 w-10
        p-1 mr-1
        //dark:text-white
        text-white
            //text-black
        dark:bg-cyan-600
            bg-cyan-600
        rounded-full
        //bg-slate-50
        //border
        //shadow-md
        //text-white
        hover:bg-sky-600
        hover:text-white
        cursor:pointer
    `,
    labelAdd:`
        p-1 px-8
        text-white
        rounded-full
        dark:bg-cyan-600
        bg-cyan-600
        cursor-default
    `,
    filter:`
        invisible
        h-8 w-8
        p-1 mr-1
        //dark:text-white
        text-white
            //text-black
        rounded
        //bg-slate-50
        //border
        //shadow-md
        //text-white
        hover:bg-slate-600
        hover:text-white
        cursor:pointer
    `,
    search:{
        root:`
            relative
            flex items-center
        `,
    },
    tools:{
        root:`
            absolute top-4 right-2
            flex items-center
            z-10
        `,
        settings:`
            w-6 h-6
            text-gray-400
            //hover:bg-gray-200
            hover:text-black
        `,
    },
    search: {
        root:`
            relative
            min-w-full
        `,
        /*
        container:`
            flex justify-between items-center
            //bg-gray-100
            py-2
        `,
        */
        container:`
            relative
            block
            w-full h-16
        `,
        searchIcon: `
            h-7 w-7
            ml-2
            //hover:bg-gray-200
            hover:text-white
            text-gray-400
        `,

        hide:`
            absolute
            right-5 top-1.5
            text-gray-400
            //hover:bg-gray-200
            hover:text-red-500
        `,
        input:`
            block
            w-full
            py-2
            pl-14
            pr-20
            outline-none
            
            focus:bg-black/20
            sm:text-sm
            w-full
            min-h-full
            px-2
            border-0
            bg-transparent
            bg-red-400
            hover:bg-black/20
            rounded-t
            //focus:border-gray-400
            placeholder:italic
            placeholder:text-transparent
            hover:placeholder:text-slate-400
            //rounded-lg
            //dark:bg-black
            //border //border-slate-300
            //focus:border-sky-500
            //focus:ring-sky-500
            //focus:ring-1
            //shadow-sm
            //focus:outline-none
        `,
    },
    icon:`
        w-6 h-6
        mr-1.5
        //text-stone-400
    `,
}), "tw")

export default function Header(props) {

    const tw = useTw()

    const {schema, icon={}, label, setSearch} = props
    const {title=label, name, data, settings=false, insertButton=false, search=false, tabs, events, api} = schema
    const { components } = app
    const { count } = data

    const buttons = {}

    return(
        <div className={tw.root}>
            <div className={tw.tools.root}>
                { buttons.filter && <FilterIcon onClick={() => api.newRow(true)} className={tw.filter}/> }
                { <InsertButton {...(typeof insertButton === 'boolean' ? {insert:insertButton} : {...insertButton, insert:true} )}/> }
                { (false && settings) && <EllipsisVerticalIcon className={tw.tools.settings}/> }
            </div>
            <Title title={title} icon={icon}/>
            <div className={tw.container}>
                <Tabs/>
            </div>
            <div className={tw.search.root}>
                { search && <Search/> }
            </div>
        </div>
    )

    function InsertButton({insert, label="Agregar", onClick, icon}) {

        return insert && <Button onClick={onHandleClick} label={label} />

        /*
        return insert && (label
            ? <Button onClick={onHandleClick} label={label} /> //<span className={tw.labelAdd} onClick={onClick}>{label}</span>
            : <PlusIcon onClick={() => api.newRow(true)} className={tw.add}/>
        )
        */
        function onHandleClick() {
            //api.post()
            if(typeof onClick === 'object') {
                events[onClick[0]](onClick[1])
            } else
                events.addLocation('/0')
        }
    }

    function Title({title, icon}) {

        if(icon && icon[0]) {
            var component = icon[0]
            var className = icon[1]
        } else {
            var {component, className} = icon || {}
        }
    
        title = title || firstUpperCase(name)

        return ( title !== false &&
            <div className={tw.title.root}>
                <div className={tw.title.def}>
                    {component && createElement(components[component],{
                        className: tw.icon + " " +(className ? className : "")
                    })}
                    { 
                        title + (count > 0 ? ` (${count})` : "")
                    }
                </div>
            </div>
        )
    }

    function Tabs() {

        const [tab, setTab] = useState(tabs ? Object.keys(tabs)[0] : null)

        return ( tabs &&
            <div className={tw.tabs.root}>
                {Object.keys(tabs).map( it =>
                    <div key={it} className={tw.tabs.def}
                        onClick={() => setTab(it)}
                    >
                        <div className={tw.tabs.tab + tw.tabs[tab === it ? 'active':'inactive']}>
                            { it }
                        </div>
                    </div>
                )}
            </div>
        )
    }

    function Search() {

        const {field='', placeholder="" } = search
        const [{filter={}, reload}, setData] = useData(`${name}/table`, 'Search')
        const [text, setText] = useState((filter[field] && filter[field][1]) || '')

        return (
            <div className={tw.search.root}>
                <label className={tw.search.container}>
                    <span className="sr-only">Search</span>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <MagnifyingGlassIcon className={tw.search.searchIcon}
                            onClick={() => setSearch && setSearch('')}
                        />
                    </span>
                    <input className={tw.search.input}
                        defaultValue={text}
                        onChange={onChange}
                        onBlur={onSearch}
                        onKeyUp={onKeyUp}
                        placeholder={placeholder || 'Buscar...'}
                        type="text"
                        name="search"
                    />
                </label>
            </div>
        )

        function onChange(e) {

            setText(e.target.value)
        }

        function onKeyUp(e) {

            if(e.keyCode === 13)
                onSearch(e)
                //e.target.blur()
        }

        function onSearch(e) {

            const arr = {[field]:["LIKE", e.target.value]}
            setData({filter:arr, reload: !reload})
            //api.get({filter:arr})
        }
    }
}

/*
function Tools() {

    const [search, setSearch] = useState(null)

    return (
        <div className={tw.tools.root}>
            {search === null
                ? <div className={tw.tools.container}>
                    <MagnifyingGlassIcon className={tw.tools.icon + tw.tools.search}
                        onClick={() => setSearch('')}
                    />
                    { title === false && 
                        <div className={tw.title.icons}>
                            { addButton && <PlusIcon onClick={() => api.newRow(true)} className={tw.tools.add}/> }
                            <EllipsisVerticalIcon className={tw.title.config}/>
                        </div>
                    }
                </div>
                : <div className={tw.tools.container}>
                    <input className={tw.tools.input} placeholder="Buscar"/>
                    <XMarkIcon className={tw.tools.icon + tw.tools.hide}
                        onClick={() => setSearch(null)}
                    />
                </div>
            }
        </div>
    )
}
*/