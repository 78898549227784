import { authConnection } from './request'

export default function useRest(url, onSuccess, onError) {

    //const [ready, setReady] = useState(false)
    //const [data, setData] = useData(url, `useApi_${id}_${url}`)
    //const [data, setData] = useState({})

    const api = {
        get:    (data, path, scc, err) => connection('get', path, data, scc, err),
        post:   (data, path, scc, err) => connection('post', path, data, scc, err),
        put:    (data, path, scc, err) => connection('put', path, data, scc, err),
        del:    (data, path, scc, err) => connection('delete', path, data, scc, err),
    }

    function connection(method, path, data, scc, err) {

        if(typeof data === 'function') {
            err = path
            scc = data
            data = ''
            path = ''
        } else if(typeof path === 'function') {
            err = scc
            scc = path
            path = ''
        }

        authConnection[method](getPath(path), response, data)

        function response(r) {

            if(r.success === 0) {

            } else if(r.success) {

                if(err) {
                    scc(r.data)
                } else if(scc) {
                    scc(r)
                } else if(onError && onSuccess) {
                    onSuccess(r.data)
                } else if(onSuccess)
                    onSuccess(r)

            } else {

                if(err) {

                    err(r.error)
                } else if(scc) {
                    scc(r)
                } else if(onError) {
                    onError(r.error)
                } else if(onSuccess)
                    onSuccess(r)
            }
        }    
    }

    function getPath(path) {

        return url + (path ? "/"+path : "")
    }

    return [api]
}




