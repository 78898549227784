import { useEffect, useState } from 'react'
import { authConnection } from './request'

export default function useRest(url, dat, onSuccess, onError) {

    const [ready, setReady] = useState(false)
    const [data, setData] = useState({})

    const api = {
        get:    (dat, path) => connection('get', path, dat),
        post:   (dat, path) => connection('post', path, dat),
        put:    (dat, path) => connection('put', path, dat),
        del:    (dat, path) => connection('delete', path, dat),
    }

    useEffect(() => {
        if(!ready) {
            api.get(dat)
            setReady(true)
        }
    })

    function connection(method, path, data) {

        authConnection[method](getPath(path), response, data)
    }

    function response(res, err) {

        if(err)
            console.log("ERROR", err)
        else
            handleCallback(res)
    }

    function handleCallback(dat) {
        setData(dat)

        if(onSuccess && onError) {

            if(dat.success === true)
                onSuccess(dat.data)
            else if(dat.success === false)
                onError(dat.error)

        } else if(onSuccess) {
            onSuccess(dat)
        }

    }

    function getPath(path) {

        return url + (path ? "/"+path : "")
    }

    return [api, data]
}





