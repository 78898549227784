import { createElement } from "react"
import Table from "../Table"
import Form from "../Form"

const schemaComponents = {
    table:Table,
    form:Form,
}

export default function Schema(props) {

    const {type='table'} = props

    return schemaComponents[type] && createElement(schemaComponents[type], {
        ...props
    })
}