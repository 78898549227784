import { CalendarDaysIcon, UserGroupIcon, CogIcon, DocumentIcon, UserIcon } from "@heroicons/react/24/outline";
import { FolderOpenIcon, CalendarIcon, IdentificationIcon, UserCircleIcon, UsersIcon, CurrencyDollarIcon, KeyIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import Agenda from "../routes/agenda/Agenda";
import Main from '../routes/layout/Main'
import Login from "../routes/authentication/Login";
import Logout from "../routes/authentication/Logout";
import ResetPassword from "../routes/authentication/ResetPassword";
import UpdatePassword from "../routes/authentication/UpdatePassword";
import Membresias from "../routes/membresia/Membresias";
import set from '../vio/components/set'
import { setLocation, addLocation } from "../vio/libs/location";
import ReporteMembresias from "../routes/reportes/ReporteMembresias";

const classes = 'text-plate-500 text-yellow-400 text-blue-500 text-lime-500 text-stone-400 text-sky-500 text-green-500 text-plate-200 text-orange-400 text-yellow-500 text-violet-500 text-sky-500'

const components = {
    Login,
    Logout,
    Agenda,
    ResetPassword,
    UpdatePassword,
    Membresias,
    Main,
    UserGroupIcon,
    UserCircleIcon,
    FolderOpenIcon,
    IdentificationIcon,
    UsersIcon,
    KeyIcon,
    UserGroupIcon,
    CalendarDaysIcon,
    CogIcon,
    CurrencyDollarIcon,
    CalendarIcon,
    WrenchScrewdriverIcon,
    ReporteMembresias,
    DocumentIcon,
    UserIcon,
    IdentificationIcon,
    UsersIcon,
}

const events = {
    addLocation,
    setLocation: props => setLocation(props),
    setLocationId: (path, id) => {
        addLocation(`${path}/${id}`)
    }
}

const schemas = {}

var app = {
    components,
    schemas,
    events,
    set,
    api:{
        url: process.env.NODE_ENV === 'development' ? 'http://192.168.0.17:5002/' : 'https://api.dicet.org/',
    },
    authentication:{
        required:true,
        route:'login',
    },
    routes:{
        login:{
            menu:false,
            layout:false,
            authenticate:false,
            component:'Login',    
        },
        reset_password:{
            menu:false,
            layout:false,
            authenticate:false,
            component:'ResetPassword',
        },
        logout:{
            menu:false,
            layout:false,
            authenticate:false,
            component:'Logout',
            props:{
                remove:['token', 'user'],
                location:'login',
            }
        },
    }
}

export default app