import { login } from './login'
import solicitudes_detalle from './solicitudes_detalle'
import productos from './productos'

const ctx = {
  dispatchers:{},
  dispatchersMetadata:{},

  data:{
    config: {
      theme: {
        mode: 'light'
      }
    }
  },
  metadata:{},
  schema:{
    login,
    solicitudes_detalle,
    productos,
  },
  themes:{
    currentTheme:1,
    items:[

    ]
  },
  sockets:{
  },
  switches:[
      ["light", true],
  ],
}

export { ctx }