import useTheme from '../context/useTheme'

const useTw = () => useTheme(theme => ({
    root:`
        //min-w-0
        rounded
        //rounded-3xl
        //bg-[#ffffff]
        //shadow-neu
        shadow-xl
        text-black
        
        dark:text-white
        border
        //border-gray-300
    `,
    container:`
        //p-8
        dark:bg-cyan-50/20
    `,
    default:`
        bg-white
        dark:bg-stone-800
    `,
    row:`
        flex
    `,
}), "tw")

export default function Card({children, container, row, ready=true, className}) {

    const tw = useTw()
    var style = ""

    if(row)
        style += tw.row

    if(container)
        style += tw.container
    else
        style += tw.default

    return ( ready &&
        <div className={tw.root +" "+ style + " " + className}>
            { children}
        </div>
    )  
}
