import axios from 'axios'
import app from '../../data/app';
import { getToken } from './storage';
import setError from '../context/setError';

function getRequest(authorized, method, path, data=null, headers={}) {

	const auth = authorized ? { authorization:getToken() } : null;

	if(method === 'get' && data)
		path += `?__query=${encodeURIComponent(JSON.stringify(data))}`

	const mixin = {
		method,
		url: app.api.url + path,
		...(auth || headers ? {'headers': {...auth, ...headers}} : null),
		data,
	}

    //console.log("CONNECTION", mixin)
	return 	axios(mixin);
}

const connection = {

	get: (p, dis, d, type, h) => {

		return stateConnection(false, 'get', p, dis, d, type, h);
	},
	post: (p, dis, d, type, h) => {

		return stateConnection(false, 'post', p, dis, d, type, h);
	},
	put: (p, dis, d, type, h) => {

		return stateConnection(false, 'put', p, dis, d, type, h);
	},
	delete: (p, dis, d, type, h) => {

		return stateConnection(false, 'delete', p, dis, d, type, h);
	},
}

const authConnection = {

	get: (p, dis, d, type, h) => {

		return stateConnection(true, 'get', p, dis, d, type, h);
	},
	post: (p, dis, d, type, h) => {

		return stateConnection(true, 'post', p, dis, d, type, h);
	},
	put: (p, dis, d, type, h) => {

		return stateConnection(true, 'put', p, dis, d, type, h);
	},
	delete: (p, dis, d, type, h) => {

		return stateConnection(true, 'delete', p, dis, d, type, h);
	},
}

function stateConnection(authorized, method, path, dispatch, data, type='', headers) {

	//	Payload state PESV+SW

	//	null = No iniciado
	//	0 = iniciado y cargando
	//	undefined = error en carga
	//	[] = resultado exitoso devuelto

	dispatch(dispRes(
		type,
		result({
			success:0,
		})
	))

	return getRequest(authorized, method, path, data, headers).then(
		(response) => {
			dispatch(dispRes(
				type,
				response.data
			))
		},
		(error) => {
			console.log("ERROR..", error)
			////.log(error.toJSON())

			var mixin;

			if(error.response) {

				mixin = dispRes(
					type,
					result({
						success:false,
						code:error.response.status,
						error:error.response.status+": "+error.response.statusText,
						detail:error.response,
					})
				); 
				//setError(error.response.status+": "+error.response.statusText)
			}
			else if(error.toJSON().message === "Network Error") {

				mixin = dispRes(
					type,
					result({
						success:false,
						code:'00',
						error:"Revisa tu conexón a internet.",
					})
				);				
				//setError("Revisa tu conexión a internet.")
			}
			else {

				mixin = dispRes(
					type,
					result({
						success:false,
						code:'NULL',
						error:"Revisa tu conexón a internet.",
					})
				);				
				//setError("Revisa tu conexión a internet.")

			}

			dispatch(mixin);
		}
	);
}

function dispRes(type, payload) {

	return (
		type ?
			{ type:type, payload:payload }
		:
			payload
	);
}

function result({success, payload, message, error, code, detail}) {

	const res = { success };

	if(payload !== undefined)
		res.payload = payload;

	if(message !== undefined)
		res.message = message;

	if(error !== undefined)
		res.error = error;

	if(code !== undefined)
		res.code = code;

	if(detail !== undefined)
		res.detail = detail;

	return res;

}

/*
const authRequest = {

	get: (p, d, h) => {

		return getRequest(true, 'get', p, d, h);
	},
	post: (p, d, h) => {

		return getRequest(true, 'post', p, d, h);
	},
	put: (p, d, h) => {

		return getRequest(true, 'put', p, d, h);
	},
}

const request = {

	get: (p, d, h) => {

		return getRequest(false, 'get', p, d, h);
	},
	post: (p, d, h) => {

		return getRequest(false, 'post', p, d, h);
	},
	put: (p, d, h) => {

		return getRequest(false, 'put', p, d, h);
	},
}
*/


export { connection, authConnection };