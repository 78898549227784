import { useState } from "react";
import useTheme from "../../vio/context/useTheme"
import useRest from "../../vio/libs/useRest";
import { setToken, setStorage } from "../../vio/libs/storage";
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { setLocation } from "../../vio/libs/location";
import updater from "../../vio/context/updater";
import { mapProperties } from "../../vio/libs/utils";
import app from "../../data/app";

const useTw = () => useTheme(theme => ({
  root:`
    relative
    flex justify-center
    min-w-full min-h-screen
    text-white
  `,
  left:`
    flex justify-center items-center
    w-1/4
    //bg-white/40
  `,
  right:`  
    bg-red-400
  `,
  input:`
    w-64
    m-2
  `,
  form:{
    root:`
      flex
      min-w-full
      min-h-full
      items-center
      justify-center
      py-12
      px-4
      sm:px-6
      lg:px-8
    `,
    container: `
      w-full
      max-w-md
      space-y-8
    `,
    welcome:`
      text-center
      text-4xl
      font-thin
      tracking-tight
      text-black
    `,
    containerError:`
    `,
    error:`
      text-red-400
      text-justify
    `,
    form:`
      space-y-6
    `,
    caption:`
      mt-24
      text-center
      text-3xl
      tracking-tight
      text-gray-500  
    `,
    containerInput:`
      -space-y-px
      rounded-md
      shadow-sm
    `,
    inputLabel:`
      sr-only
    `,
    inputEmail:`
      relative
      block
      w-full
      appearance-none
      rounded-none
      rounded-t-md
      border
      border-gray-300
      px-3
      py-2
      text-gray-900
      placeholder-gray-500
      focus:z-10
      focus:border-indigo-500
      focus:outline-none
      focus:ring-indigo-500
      sm:text-sm
    `,
    inputPassword:`
      relative
      block
      w-full
      appearance-none
      rounded-none
      rounded-b-md
      border
      border-gray-300
      px-3
      py-2
      text-gray-900
      placeholder-gray-500
      focus:z-10
      focus:border-indigo-500
      focus:outline-none
      focus:ring-indigo-500
      sm:text-sm
    `,
    forget:`
      font-medium
      text-indigo-600
      hover:text-indigo-900
      cursor-default
    `,
    containerSubmit: `
      flex
      items-center
      justify-end
    `,
    submit:`
      group
      relative
      flex
      w-full
      justify-center
      rounded-md
      border
      border-transparent
      bg-indigo-600
      py-2
      px-4
      text-sm
      font-medium
      text-white
      hover:bg-indigo-700
      focus:outline-none
      focus:ring-2
      focus:ring-indigo-500
      focus:ring-offset-2
    `,
    lockIcon:`
      h-5
      w-5
      text-indigo-500
      group-hover:text-indigo-400
    `,
  }
}), "tw")

export default function Login({location, ...props}) {

  const tw = useTw()
  const emailParam = location.params.get('email') || ""

  return (
    <div className={tw.root} style={{ background: 'url(footerbg.jpg) bottom repeat-x, url(headerbg.jpg) top no-repeat, url(bodybg.jpg) top repeat-x',
      backgroundColor: '#e0edf6'}}>
      <div className={tw.left}>
        <Form/>
      </div>
    </div>
  )

  function Form() {

    const tw = useTw()
    const [email, setEmail] = useState(emailParam)
    const [statusEmail, setStatusEmail] = useState(0)
    const [password, setPassword] = useState()
    const [emailValid, setEmailValid] = useState()
    const [error, setError] = useState()
    const [{get, post}] = useRest("login")

    if(emailParam && emailValid === undefined) {
      setEmailValid(null)
      getStatusEmail(email)
    }

    console.log("email", email, statusEmail)

    return (
      <>
        <div className={tw.form.root} >
          <div className={tw.form.container}>
            <div>
              <h2 className={tw.form.welcome}>
                ¡Bienvenido al Club!
              </h2>
              <h2 className={tw.form.caption}>
                Ingresa a tu cuenta
              </h2>
            </div>
            <div className={tw.form.form}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className={tw.form.containerInput}>
                <div>
                  <label htmlFor="email" className={tw.form.inputLabel}>
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onBlur={e => getStatusEmail(e.target.value)}
                    className={tw.form.inputEmail}
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label htmlFor="password" className={tw.form.inputLabel}>
                    Contraseña
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className={tw.form.inputPassword}
                    placeholder={statusEmail === 1 ? "Crea una contraseña" : "Contraseña"}
                  />
                </div>
              </div>

              <div className={tw.form.containerError}>
                <span className={tw.form.error}>
                  { error }
                </span>
              </div>

              <div>
                <button
                  className={tw.form.submit}
                  onClick={() => login()}
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon className={tw.form.lockIcon} aria-hidden="true" />
                  </span>
                  Ingresar
                </button>
              </div>

              <div className={tw.form.containerSubmit}>
                <div className="text-sm">
                  <span className={tw.form.forget} onClick={resetPassword}>
                    { statusEmail == 2 && "¿Olvidaste tu contraseña?" }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

    function getStatusEmail(email) {

      get({email}, "", ({_status}) => {

        setStatusEmail(_status)
        setError("")

      }, error => {

        setStatusEmail(0)
        setError(error)    
      })
    }

    function login() {
        post({email, password}, ({token, nombre, nivel}) => {

        setToken(token)
        setStorage('user', {nombre, nivel})
        setLocation()

      }, setError)
    }

    function resetPassword() {

      setLocation('reset_password', {email})
    }
  }
}