import { ctx } from '../../data/context'
import setDataContext from './setDataContext'
import updMetadata from './updMetadata'

export default function setState(data, value, op, metadata) {

    const {path, dat, attr} = setDataContext(data, value, op)

    dispatchers(path, dat[attr])
    const md = updMetadata(path, dat[attr], metadata)

    return md
}

function dispatchers(path, value) {

    ctx.dispatchers[path] && Object.keys(ctx.dispatchers[path]).forEach( id => {
        //console.log("DISP:", path, id, value)
        ctx.dispatchers[path][id](value)
    })
}