import useTheme from '../../context/useTheme'
import { createElement, useState } from "react"
import useData from "../../context/useData"
import { firstUpperCase } from '../../libs/utils'
import updater from '../../context/updater'

const useTw = () => useTheme(theme => ({
    root:`
      relative
        w-128
        p-8
      min-w-full
      text-white
    `,

    form:{
      root:`
        min-w-full min-h-full
        flex items-start justify-center
        py-12 pt-32 px-4
        sm:px-6 lg:px-8
      `,
      container: `
        w-full
        max-w-md
        space-y-8
      `,
      welcome:`
        text-center
        text-4xl
        font-thin
        tracking-tight
        text-black
      `,
      containerError:`
      `,
      error:`
        text-red-400
        text-justify
      `,
      message:`
        text-cyan-500
        text-justify
      `,
      form:`
      `,
      caption:`
        w-48
        text-left
        text-lg
        //font-medium
        tracking-tight
        text-black
        dark:text-gray-400
        
      `,
      containerInput:`
        w-96
        //flex
        //-space-y-px
        //rounded-md
        //shadow-sm
      `,
      inputLabel:`
        sr-only
      `,
      inputRoot:`
          //bg-red-400
          flex justify-between items-center
          pb-4
          border-0
      `,
      input:`
        //relative
        //block
        w-full
        appearance-none
        rounded
        border
        border-white/0
        //mb-4
        mb-1
        px-3
        py-2
        //text-gray-900
        bg-black/40
        placeholder-gray-500
        focus:z-10
        focus:border-indigo-500
        focus:outline-none
        focus:ring-indigo-500
        sm:text-sm
        text-white
      `,
      link:`
        px-1
        font-medium
        text-indigo-600
        hover:text-indigo-500
        hover:bg-indigo-200
        rounded
        cursor-default
      `,
      containerSubmit: `
        flex
        items-center
        justify-end
      `,
      submit:`
        group
        relative
        flex
        w-full
        justify-center
        rounded-md
        border
        border-transparent
        bg-indigo-600
        mt-10
        py-2
        px-4
        text-sm
        font-medium
        text-white
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2
        focus:ring-indigo-500
        focus:ring-offset-2
      `,
      lockIcon:`
        h-5
        w-5
        text-indigo-500
        group-hover:text-indigo-400
      `,
      successOptions:`
          flex justify-between
          pt-2
          text-black
      `
    }
  }), "tw")

export default function Body({schema, id}) {

    const tw = useTw()
    const { name, editable=false, columns, set, rows, src, api, events } = schema
    const dataPath = `${name}/form`
    const fields = columns || rows
    const cmp = set[editable ? 'edit':'read']

    return(
        <div className={tw.root}>
            {
              Object.entries(fields).map(([key, row]) => {

                const {field, name, label, value, def, placeholder, type, hidden} = row
                const component = cmp[type] || cmp.data

                return ( (((hidden !== true && type !== "status") && component) && 
                      <Input
                        {...{
                          key:field,
                          component,
                          index:id,
                          id,
                          label,
                          name,

                          ...row,
                          set,
                          value:value || def,

                          //src,
                          //data,
                          //setData,
                          //tables,
                          //stateRow: state,
                          //setStateRow: setState,
                          //api,
                          //events,
                          //saveOnChange:false,
                          className:editable ? " //bg-black/20 bg-black-red ":"",
                          schema,
                        }}
                      />
                ))
              })
            }
        </div>
    )

    function Input(props) {

        const {description, label, field, onBlur, name, component} = props
        const path = `${schema.name}/form/data/${field}`
        //const [data] = useData(path, 'Form')
        const tw = useTw()

        return(
            <div className={tw.form.inputRoot}>
                <h2 className={tw.form.caption}>
                    {label || firstUpperCase(field)}
                </h2>
                <div className={tw.form.containerInput}>
                    {
                      component && createElement(component, {
                        ...props,
                        //value: data === undefined ? props.value : data,
                        dataPath,
                        path,
                      })
                    }
                </div>
            </div>
        )

        /*
        <input
        id={name}
        name={name}
        type={type}
        autoComplete={`current-${name}`}
        required
        value={data}
        onChange={e => setS(e.target.value)}
        onBlur={onBlurHandler}
        onKeyUp={onKeyUpHandle}
        className={tw.form.input}
        placeholder={placeholder}
    />
        */
       /*
        function onBlurHandler() {
            blur()
        }

        function onKeyUpHandle(e) {
            if(e.keyCode === 13)
                e.target.blur()
        }

        function blur() {
            setData(s)
            onBlur && onBlur(s)
        }
        */
    }
}