import { createElement, useState } from "react";
import useTheme from "../../vio/context/useTheme";
import useRest from "../../vio/libs/useRest";
import { setStorage, setToken } from "../../vio/libs/storage";
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { setLocation } from "../../vio/libs/location";
import { CheckIcon } from "@heroicons/react/24/solid";

const useTw = () => useTheme(theme => ({
  root:`
    relative
    flex justify-center
    min-w-full min-h-screen
    text-white
  `,
  left:`
    flex justify-center items-center
    w-1/4
    //bg-white/40
  `,
  right:`  
    bg-red-400
  `,
  input:`
    w-64
    m-2
  `,
  form:{
    root:`
      min-w-full min-h-full
      flex items-start justify-center
      py-12 pt-32 px-4
      sm:px-6 lg:px-8
    `,
    container: `
      w-full
      max-w-md
      space-y-8
    `,
    welcome:`
      text-center
      text-4xl
      font-thin
      tracking-tight
      text-black
    `,
    containerError:`
    `,
    error:`
      text-red-400
      text-justify
    `,
    message:`
      text-cyan-500
      text-justify
    `,
    form:`
    `,
    caption:`
        pt-4 px-1 pb-2
      text-left
      text-lg
      tracking-tight
      text-gray-500
    `,
    containerInput:`
      -space-y-px
      rounded-md
      //shadow-sm
    `,
    inputLabel:`
      sr-only
    `,
    inputRoot:`
        //bg-red-400
        pb-4
        border-0
    `,
    input:`
      relative
      block
      w-full
      appearance-none
      rounded
      border
      border-gray-300
      //mb-4
      mb-1
      px-3
      py-2
      text-gray-900
      placeholder-gray-500
      focus:z-10
      focus:border-indigo-500
      focus:outline-none
      focus:ring-indigo-500
      sm:text-sm
    `,
    link:`
      px-1
      font-medium
      text-indigo-600
      hover:text-indigo-500
      hover:bg-indigo-200
      rounded
      cursor-default
    `,
    containerSubmit: `
      flex
      items-center
      justify-end
    `,
    submit:`
      group
      relative
      flex
      w-full
      justify-center
      rounded-md
      border
      border-transparent
      bg-indigo-600
      mt-10
      py-2
      px-4
      text-sm
      font-medium
      text-white
      hover:bg-indigo-700
      focus:outline-none
      focus:ring-2
      focus:ring-indigo-500
      focus:ring-offset-2
    `,
    lockIcon:`
      h-5
      w-5
      text-indigo-500
      group-hover:text-indigo-400
    `,
    successOptions:`
        flex justify-between
        pt-2
        text-black
    `
  }
}), "tw")

export default function ResetPassword({params}) {

  const tw = useTw()
  const emailParam = params && params.get('email')

  return (
    <div className={tw.root} style={{ background: 'url(footerbg.jpg) bottom repeat-x, url(headerbg.jpg) top no-repeat, url(bodybg.jpg) top repeat-x',
      backgroundColor: params && '#e0edf6'}}>
      <div className={tw.left}>
        <Form/>
      </div>
    </div>
  )

  function Form() {

    const tw = useTw()
    const [email, setEmail] = useState(({status:undefined, value:emailParam, sended:undefined}))
    const [password, setPassword] = useState({status:undefined, value:''})
    const [codigo, setCodigo] = useState({status:undefined, value:""})
    const [message, setMessage] = useState()
    const [error, setError] = useState()
    const [{get, post, put}] = useRest("reset_password")

    const emailDescription = email.sended === false ? "Enviando código a:" : "Correo electrónico"
    const codeDescription = "Te enviamos un código a tu correo, ingrésalo a continuación por favor:"

    const codeBottom = (email.status && codigo.status === false) && <Link label="No me llegó el correo" onClick={sendEmailCode}/>

    if(emailParam && email.status === undefined) {
        sendEmailCode(email.value)
    }

    return (
      <>
        <div className={tw.form.root} >
            <div key="title" className={tw.form.container}>
                <h2 className={tw.form.welcome}>
                    Cambiar contraseña
                </h2>
                <div key="content" className={tw.form.form}>
                    <div key="group" className={tw.form.containerInput}>
                        {[
                            <Input key="email" name="email" description={emailDescription} placeholder="Email" state={email} setState={setEmail} onBlur={sendEmailCode}/>,
                            email.status && <Input key="codigo" name="codigo" description={codeDescription} placeholder="Código" state={codigo} setState={setCodigo} onBlur={authCode} bottom={codeBottom}/>,
                            codigo.status && <Input key="password" name="contraseña" type="password" description="Escribe tu nueva contraseña" placeholder="Nueva contraseña" state={password} setState={setPassword}/>
                        ]}
                    </div>

                    <Error error={error}/>
                    {
                        password.status
                            ?   
                                <div>
                                    <Message message={message}/>
                                    <div className={tw.form.successOptions}>
                                        
                                        <div>
                                            <Link label="Probar mi nueva contraseña" location="logout"/>
                                        </div>
                                        <div>
                                            <Link label="Ir a la página de inicio" location=""/>
                                        </div>
                                    </div>
                                </div>
                            : codigo.status && <Button label="Cambiar contraseña" onClick={resetPassword} icon={CheckIcon}/>
                    }
                </div>
            </div>
        </div>
      </>
    )

    function Link({label, onClick, location, query}) {

        onClick = onClick
            ? onClick
            : () => setLocation(location, query)

        return(
            <div className="text-sm">
                <span className={tw.form.link} onClick={onClick} >
                    { label }
                </span>
            </div>
        )
    }

    function Error({error}) {

        return(
            <div className={tw.form.containerError}>
                <span className={tw.form.error}>
                    { error }
                </span>
            </div>
        )
    }

    function Message({message}) {

        return(
            <div className={tw.form.containerError}>
                <span className={tw.form.message}>
                    { message }
                </span>
            </div>
        )
    }

    function Button({onClick, icon, label}) {

        return(
            <div>
                <button
                    className={tw.form.submit}
                    onClick={onClick}
                >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        {
                            createElement(icon, {
                                className:tw.form.lockIcon,
                                "aria-hidden":true
                            })
                        }
                    </span>
                    {label}
                </button>
            </div>
        )
    }

    function Input({name, type='input', description, placeholder, state, setState, onBlur, bottom}) {

        const [s, setS] = useState(state.value)

        return(
            <div className={tw.form.inputRoot}>
                <h2 className={tw.form.caption}>
                    {description}
                </h2>
                <div>
                    <label htmlFor={name} className={tw.form.inputLabel}>
                        {name}
                    </label>
                    <input
                        id={name}
                        name={name}
                        type={type}
                        autoComplete={`current-${name}`}
                        required
                        value={s}
                        onChange={e => setS(e.target.value)}
                        onBlur={onBlurHandler}
                        onKeyUp={onKeyUpHandle}
                        className={tw.form.input}
                        placeholder={placeholder}
                    />
                    { bottom }
                </div>
            </div>
        )

        function onBlurHandler() {
            blur()
        }

        function onKeyUpHandle(e) {
            if(e.keyCode === 13)
                e.target.blur()
        }

        function blur() {
            setState({...state, value:s})
            onBlur && onBlur(s)
        }
    }

    function sendEmailCode(em) {

        if(email.status !== true) {

            em = typeof em === "string" ? em : email.value

            setEmail({value:em, status:null, sended:false})
            setCodigo({value:'', status:false})
            setError("")

            get({email:em}, ({_status}) => {

                setEmail({value:em, status:_status, sended:true})
                setError("")

            }, er => {

                setEmail({value:em, status:false, sended:undefined})
                setError(er)
            })
        }
    }

    function authCode(cd) {

        cd.length > 0 &&
            post({email:email.value, code:cd}, ({_status}) => {

                console.log(_status)
                setCodigo({value:cd, status:_status})
                setError("")

            }, setError)
    }

    function resetPassword() {

        put({email:email.value, code:codigo.value, password:password.value}, ({token, nombre, nivel}) => {

            setPassword({value:password.value, status:1})
            setToken(token)
            setStorage('user', {nombre, nivel})
            setMessage("¡Tu contraseña se modificó exitosamente!")
            setError("")

        }, setError)
    }
  }
}