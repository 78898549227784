import useTheme from "../../vio/context/useTheme"
import Card from "../../vio/components/Card"
import Table from "../../vio/components/Table"
import Form from "../../vio/components/Form"
import { IdentificationIcon, UserIcon, UsersIcon } from "@heroicons/react/24/outline"
import rest from "../../vio/libs/useRest"
import setError from "../../vio/context/setError"
import Dayjs from "dayjs"
import Button from "../../vio/components/Button"
import { useState } from "react"
import { setLocation } from "../../vio/libs/location"

const useTw = () => useTheme(theme => ({
    root:`
        flex
        p-8
    `,
    form:`
        flex flex-col //justify-center items-center
    `,
    tables:`
        p-5 pt-0
    `,
    buttonContainer:`
        flex justify-center
        min-w-full min-h-full
        py-4
    `
}), "tw")

export default function Membresia(props) {

    const tw = useTw()
    const {targetParams} = props.location
    const [id, membresia, idMembresia] = targetParams
    const labelTitleBtn = "Enviar correo electrónico de invitación"
    const [titleBtn, setTitleBtn] = useState(id > 0 && labelTitleBtn)
    const [api] = rest(`membresia`)

    const sendInvitation = () => {

        setTitleBtn("Enviando...")

        api.post({id}, 'invitacion', res => {

            setTitleBtn("Envío exitoso...")

            const timer = setTimeout(() => {
                setTitleBtn(labelTitleBtn)
                clearTimeout(timer)
            }, 5000)

        }, setError)
    }
    const updateVencimiento = props => {
        const { schema } = props
        const { data, events } = schema
        var { ingreso, meses } = data

        if(!ingreso) {
            ingreso = Dayjs().format('YYYY-MM-DD')
            events.fields.ingreso.update(ingreso)
        }

        const vencimiento = Dayjs(ingreso).add(meses, 'month').format('YYYY-MM-DD')
        events.fields.vencimiento.update(vencimiento)
    }
    const updateCosto = props => {
        const { schema } = props 
        const { meses, personas, accesos } = schema.data

        const entradas = meses * personas * accesos

        entradas > 0 &&
            api.get({accesos:entradas}, 'tarifa', ({data}) => {

                schema.events.fields.costo.update(data)
                schema.events.fields.entradas.update(entradas)
                schema.events.fields.importe.update(entradas * data)

            }, setError)
    }
    const query = {
        where: {
            usuario_id:id
        }
    }
    const tableMap = {
        columns:{
            usuario_id:{
                def:id,
            }
        }
    }
    const membresiaMap = {
        title: (idMembresia > 0 ? "Modificar" : "Nueva") + " Membresía",
        events:{
            fields:{
                meses:{
                    onBlur: updateVencimiento
                },
                ingreso:{
                    onBlur: updateVencimiento
                },
                costo:{
                    onBlur: props => {
                        const { schema } = props
                        const { data } = schema
                        const { entradas, costo } = data
                        schema.events.fields.importe.update(entradas * costo)
                    }
                }
            },
            onGet: props => {
                updateCosto({schema: props})
            },
            onBlur: props => {

                if(['meses', 'personas', 'accesos'].includes(props.field))
                    updateCosto(props)
            },
        },
        rows:{
            usuario_id:{
                def:id,
            },
        }
    }

    const usuarioMap = {
        className:{
            card:`
                min-h-full
            `
        },
        events:{
            /*
            afterInsert: (props, res) => {

                const { events } = props

                //setTitleBtn("Enviando invitacion...")
                sendInvitation(() => events.addLocation(`/../${res._id}`), () => {
                    
                })
                console.log(props)
                alert(1)
                setLocation('/..')
            }
            */
        },
    }

    const footer = titleBtn &&
        <div className={tw.buttonContainer}>
            <Button label={titleBtn} type="secondary" onClick={sendInvitation}/>
        </div>

    return (
        membresia
        ? <>
            <Form {...props} name="membresia" id={idMembresia} map={membresiaMap} query={query} icon={['UserIcon', '']}/>
        </>
        : <div className={tw.root}>
            <div className={tw.form}>
                <Form {...props} name="usuario" title={false} id={id} map={usuarioMap} footer={footer}/>
            </div>
            { id > 0 &&
                <div className={tw.tables}>
                    <Table key="membresias" {...props} map={tableMap} name="membresias" query={query} icon={['IdentificationIcon', '']}/>
                    <br/>
                    <Table key="integrantes" {...props} map={tableMap} name="integrantes" query={query} icon={['UsersIcon', '']}/>
                </div>
            }
        </div>    
    )
}

//<Card container row></Card>