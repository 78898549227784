import useTheme from "../../context/useTheme"
import Bar from "./Bar"
import Schema from "./Schema"
import Error from "../../../routes/layout/Error"

const useTw = () => useTheme(theme => ({
    root:`
        relative
        flex items-center justify-center
        min-w-full h-full
        //p-8 //pt-16
        bg-slate-200
        dark:bg-black
            //bg-[#ededed]
        dark:text-white
             text-black
        `
}), "tw")

export default function Content(props) {

    const tw = useTw()
    const { mode, target, children, ...pro} = props

    return (
        <div className={tw.root}>
            <Bar {...props}/>
            {
                children
                ||
                <Schema
                    {...{...target, name:target.name || props.route, mode, location:pro}}
                />
            }
            <Error/>
        </div>
    )
  }