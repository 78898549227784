import { useState } from 'react'
import { ctx } from '../../data/context'
import updater from './updater'

export default function useData(data, id=data, o="a", metadata) {

    var val = ctx.data

    const a = data.split("/")

    for(let p of a) {

        //if(val[p] === undefined)
        //    val[p] = {}

        val = val[p]

        if(val === undefined) {
            break;
        }
    }


    const [state, setState] = useState(val)
    //console.log("DISPATCHER", data, id)

    if(ctx.dispatchers[data])
        ctx.dispatchers[data][id] = setState
    else
        ctx.dispatchers[data] = {
            [id]: setState
        }

    //console.log(ctx)

    return [state, updater(data, o, metadata)]
}

/*

    1800 cfdi
     450 cancelaciones

    1400 pagos
    1400 carta porte


import { useState } from 'react'
import { ctx } from '../../context'
import updater from './updater'

export default (data, id=data, o="a") => {

    if(data) {
        var val = ctx.data
        data.split("/").forEach(i => val = val[i] );
    } else {
        var val = null
    }

    const [state, setState] = useState(val)

    if(data) {

        if(ctx.dispatchers[data])
            ctx.dispatchers[data][id] = setState
        else
            ctx.dispatchers[data] = {
                [id]: setState
            }
    }

    return [state, updater(data, o)]
}
*/