import { createContext, useReducer, useMemo } from 'react'
import useWindowSizes from './useWindowSizes'
import { ctx } from '../../data/context.js'

const Context = createContext()

const bk = ["xl",  "lg", "ml", "md", "sm", "xs"]
const breakpoints = {
	xs:    0,
	sm:  600, 
	md:  960, 
	ml: 1200, 
	lg: 1280, 
	xl: 1920
}
const barHeight = 70
const footerHeight = 90

function reducer(state, {type, payload, ...props}) {

	if(!type)
		return setState(props)

	switch(type) {
		case 'mode':
			return setState({mode: payload, isLight : (a, b) => payload === 'light' ? a:b})
		break;

		case 'sizes':
			return setSizes(payload)
		break;
	}

	function setState(data) {

		return {...state, ...data}
	}

	function setSizes({width, height, dispatch}) {

		const contentWidth = width
		const contentHeight = height - barHeight
		const bodyHeight = height - barHeight - footerHeight

		return setState({
			...state,
			width,
			height,
			barHeight,
			footerHeight,
			contentWidth,
			bodyHeight,
			contentHeight,
		})
	}
}

function ContextStore({children}) {

	const {width, height} = useWindowSizes()

	const state = useReducer(reducer, {
		...ctx.vars,
		data:ctx.data,
		mode:'light',
    });

	useMemo(
		() => state[1]({
			type:'sizes', 
			payload:{
				width,
				height,
				dispatch:state[1],
			}
		}),
		[width, height]
	)

	return (
		<Context.Provider value={state} >
			{ children }
		</Context.Provider>
	);
}

export { Context, ContextStore }