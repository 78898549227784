import { createElement } from 'react'
import useData from '../../context/useData'
import useTheme from '../../context/useTheme'
import app from '../../../data/app'

const useTw = () => useTheme(theme => ({
    root:`
        flex items-center
        p-4
        bg-slate-100
        dark:bg-black/20
        font-bold
        rounded-t
    `,
    icon:`
        w-6 h-6
        mr-1.5
        //text-stone-400
    `,
}), "tw")

export default function Header(props) {

    const tw = useTw()

    const {schema, icon={}, label} = props
    const { components } = app
    const {title=label, name } = schema


    if(icon && icon[0]) {
        var component = icon[0]
        var className = icon[1]
    } else {
        var {component, className} = icon || {}
    }

    return(
        title !== false &&
        <div className={tw.root}>
            {component && createElement(components[component],{
                    className: tw.icon + " " +(className ? className : "")
            })}
            { title || name }
        </div>
    )
}