import { createElement } from 'react'
import app from '../../data/app'
import useData from '../context/useData'
import usePath from '../libs/usePath'

export default function App(props) {

    const [mode] = useData("config/theme/mode", "AppMode")
    const location = usePath()
    const {target} = location
    const {layout=app.layout, component} = target

    //console.log(mode)
    return(
        createElement(app.components[layout] || Blank, {
                ...location,
                target,
                mode,
                app,
            },
            app.components[component] && createElement(app.components[component], {
                ...target,
                mode,
                location
            })
        )
    )

    function Blank(props) {
        return (
            props.children
        )
    }
}