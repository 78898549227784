
const setToken = (token) => {

    window.localStorage.setItem("token", JSON.stringify(token))
}

const setStorage = (attr, value) => {

    window.localStorage.setItem(attr, typeof value === 'object' ? JSON.stringify(value) : value)
}

const getStorage = (attr, json=false) => {

    var value = window.localStorage.getItem(attr)

    if(value !== undefined && value !== null)
        value = json ? JSON.parse(value) : value
    else {
        value = json ? {} : ''
    }

    return value
}

const getToken = () => {

    var token = window.localStorage.getItem("token")
    token = token === 'undefined' ? undefined : token
    return  token && JSON.parse(token)
}

const delToken = () => {

    window.localStorage.removeItem("token")
}

const removeSession = (list) => {

    list && list.forEach( it => window.localStorage.removeItem(it) )
}

export { setToken, getToken, setStorage, getStorage, removeSession, delToken}