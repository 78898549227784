import { removeSession } from "../../vio/libs/storage"
import { setLocation } from "../../vio/libs/location"

export default function Logout({props}) {

    const {remove, location, query} = props

    removeSession(remove)
    setLocation(location, query)

    return null
}