import { XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import useData from '../../vio/context/useData'
import useTheme from '../../vio/context/useTheme'
import setError from '../../vio/context/setError'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

const useTw = () => useTheme(theme => ({
    root:`
        absolute bottom-0
        z-10
        bg-green-400
        min-w-full
    `,
    banner:`
        relative
        flex items-center
        h-14
        px-4
        bg-red-600
    `,
    description:`
        p-2
        text-xl
        text-white
        font-thin
    `,
    icon:`
        w-9 h-9
        text-white
    `,
    close:`
        absolute right-2
        w-9 h-9
        text-white
    `
}), "tw")

export default function Error({}) {

    const tw = useTw()
    const [error={}] = useData("error", "Error")

    if(error.description)
        var time = setInterval(() =>  {
            setError("")
            clearInterval(time)
        }, 50000);

    return (
        error.description &&
        <div className={tw.root}>
            <div className={tw.banner}>
                <ExclamationCircleIcon className={tw.icon}/>
                <span className={tw.description}>
                    { error.description }
                </span>
                <XMarkIcon onClick={() => setError("")} className={tw.close}/>
            </div>
        </div>
    )
}