
function setLocation(path="", query) {

    var qry = ''

    if(query) {

        Object.entries(query).forEach(([key, value]) => 
            qry += `${key}=${value}&`
        )

        qry = `?${qry.slice(0, -1)}`
    }

    const {origin} = getLocation()

    if(path.substring(0, 1) !== "/")
        path = "/"+path

    path = path.replaceAll('//','/')

    document.location.href = `${origin}${path}${qry}`
}

function getLocation() {

    const origin    = window.location.origin
    const protocol  = window.location.protocol    // = “http:
    const host      = window.location.host        // = “localhost:3000
    const pathName  = window.location.pathname    // = “/dashboard/main
    const params    = new URL(window.location.href).searchParams //window.location.search      // = “?id=1234
    const url    = `${protocol}//${host}/`

    var pathList = pathName.split('/').slice(1)
    var route = pathList[pathList.length-1]

    return {protocol, host, pathName, params, url, origin, pathList, route}
}

function addLocation(path) {

    const { pathName } = getLocation()

    setLocation(pathName + path)
}

export { setLocation, addLocation, getLocation}