import { setToken } from "../vio/libs/storage"

const login = {
    attr:{
        _image:{},
        Usuario:{
            valid: { call: 'user/picture' }
        },
        Password:{
            type: 'password',
            valid: {
                call: 'authenticate',
                onSuccess: ({data}) => {

                    setToken(data.token)
                    window.location.href = "/home"
                },
                onError: (err) => {

                }
            }
        },
    },
}

export { login }