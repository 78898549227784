import useTheme from "../../vio/context/useTheme"
import Table from "../../vio/components/Table"
import dayjs from "dayjs"

const useTw = () => useTheme(theme => ({
    root:`
        relative
        flex items-center justify-center
        min-w-full h-full
        p-8 pt-16
        dark:bg-black
             bg-slate-200
            //bg-[#ededed]
        dark:text-white
             text-black
        `
}), "tw")

export default function ReporteMembresias(props) {

    const tw = useTw()
    //const { mode, target, children, ...pro} = props

    const query={
        where:{
            vencimiento: [">=", dayjs().add(1, 'day').format('YYYY-MM-DD')]
        }
    }

    const map = {
        className:{
            card:`
                //min-w-full
            `
        },
        columns:{
            nombre: { className: ' w-50 ' },
            meses:{ className: ' w-16 ' },
            //ingreso: { className: ' w-38 ' },
            //vencimiento: { className: ' w-38 ' }
            personas:{ className: ' w-16 ' },
            accesos: { className: ' w-16 ' },
            costo: { className: ' w-16 ' },
        }
    }

    return (
        <div className={tw.root}>
            <Table name="reporte_membresias" map={map} icon={["DocumentIcon", ""]} query={query}/>
        </div>
    )
  }