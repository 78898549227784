import { createElement, useState } from 'react'
import useTheme from '../context/useTheme'
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, EllipsisVerticalIcon, LockOpenIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { SwatchIcon, LockClosedIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/solid"
import { currency, dateToString, decimal } from '../libs/utils'
import setError from '../context/setError'
import setDataContext from '../context/setDataContext'
import updater from '../context/updater'
import useData from '../context/useData'

const colors = require('tailwindcss/colors')

const useTw = () => useTheme(theme => ({
    check:{
        root: `
            w-6 h-6
            rounded
            //bg-black/40
            dark:hover:bg-gray-800
                 hover:bg-gray-100
        `,
        edit: `
            w-6 h-6
            rounded
            dark:hover:bg-gray-800
                 hover:bg-gray-100
        `
    },
    data:{
        root: `
            relative
            flex items-center
            text-md
            text-black
            dark:text-white
            //bg-black/20
            
        `,
        def:`
            //w-full
            flex items-center
            p-2
            //pl-6
            //bg-green-800
            //border-b
        `,
        container:`
            //absolute
            //bg-red-400
        `,
        icon:`
            //absolute
            w-5 h-5
            font-sans
            inset-y-0 left-0
            flex items-center
            //dark:text-slate-400
            font-bold
            text-md
        `,
        edit: `
            relative
            flex items-center
            w-full
            text-md
            //dark:text-black
            //dark:bg-black
            outline-0
            //bg-red-400
        `,
        input:`
            //w-48 h-10
            //w-full
            px-2
            //text-md
            //dark:text-black
            //placeholder:text-white
            text-black
            dark:hover:text-white
            //bg-transparent
            //bg-gray-100
            dark:hover:bg-black/20
            rounded-md

            //dark:bg-black
                 //bg-gray-100
            //outline-0
            
        `,
    },
    formats:{
        decimal:`
            w-24
            justify-end
        `,
        money:`
            w-28
            justify-end
        `,
        varchar:`
            w-70
        `,
    },
    color:{
        root: `
            w-10 h-10
            rounded-full
        `,
        edit: `
            relative
            flex
            rounded-tl-full rounded-bl-full
            p-1
            bg-gray-200
        `,
        def: `
            w-10 h-10
            rounded-full
            bg-gray-200
            border border-gray-400
        `,
        picker:{
            root:`
                bg-red-500
            `,
            container:`
                absolute
                overflow-hidden
                absolute top-1/2 transform -translate-y-1/2
                p-1
                bg-gray-200
                rounded
            `,
            //absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
            col:`
                flex
            `,
            element:`
                w-5 h-5
                //p-1
                rounded
                bg-gray-200
            `,
        },
        icon: `
            absolute top-4 right-1 transform -translate-y-1/2
            w-6 h-6
            text-blue-400
        `,
    },
    image:{
        def: `
            w-14 h-14
            rounded-xl
            border
            border-slate-200
        `
    },
    set:{
        root:`
            flex
        `,
        item:`
            mr-1 mt-1 px-2 py-0.5
            rounded
            text-sm
            text-white
            font-medium
            //uppercase
            //bg-black
        `
    },
    subtitle:`
        text-sm
        text-gray-500
    `,
    input:{
        root:`
            h-10
            px-2
            rounded
            border-0
            bg-gray-100
            placeholder:italic
        `,
    },
    button:{
        def:`
            h-8 w-8
            p-1
            rounded-lg
            dark:bg-black
                 bg-white
            text-gray-300
        `,
        ok:`
            visible
            text-green-400
            hover:bg-green-100
            hover:text-green-500
        `,
        cancel:`
            visible
            text-red-400
            hover:bg-red-100
            hover:text-red-600
        `,
        edit:`
            invisible
            group-hover:visible
            text-yellow-600
            hover:bg-yellow-200
        `,
        delete: {
            root:`
                relative
                invisible
                group-hover:visible
                flex
            `,
            trash:`
                peer
                text-red-600
                hover:bg-red-100
                //hover:absolute
                //hover:-left-4
            `,
            lock:`
                invisible
                peer-hover:visible
                absolute
                -right-8
                text-orange-600
                hover:visible
                hover:bg-orange-100

            `,
        },
        lock: {
            root:`
                relative
                flex
            `,
            def:`
                peer
                text-orange-600
                hover:bg-orange-100
            `,
            unlock:`
                invisible
                peer
                peer-hover:visible
                absolute
                text-green-600
                hover:visible
                hover:bg-green-100
            `,
            trash:`
                invisible
                peer-hover:visible
                absolute
                -right-8
                text-red-600
                hover:visible
                hover:bg-red-100
                //hover:absolute
                //hover:-left-4
            `,
        },
        more:{
            root:`
                relative
            `,
            def:`
                peer
                invisible
                group-hover:visible
                h-8 w-8
                p-1
                rounded-lg
                text-gray-500
                hover:text-white
            `,
            menu:`
                absolute
                invisible
                peer-hover:visible
                w-52
                rounded
                bg-gray-500
                text-sm
            `,
            item:`
                flex
                p-1
            `,
            icon:`
                w-5 h-5
                p-1
                text-white
            `,
        }
    },
    status: {
        mask:`
            //peer
            //invisible
        `,
        open:`
            w-5 h-5
            //peer-hover:visible
            text-green-700
        `,
        close:`
            w-5 h-5
            //bg-white/10
            text-red-700
        `,
        remove:`
            w-5 h-5
            //bg-white/10
            text-red-500
        `,
    },
    columns:{
        def:`
            flex items-center
        `,
        item:`
            mr-4
            last:mr-0
        `
    },
    rows:{
        def:`
            flex flex-col
        `,
        item:`
        `
    }
}), "tw")

function Money({className}) {
    return <span className={className}>$</span>
}

function Existencia(props) {

    const {data:{existencia, stock_minimo, stock_maximo}, className} = props
    var cn = className || ""

    if(existencia < stock_minimo)
        cn += ' !text-orange-400 '
    else if(existencia > stock_maximo)
        cn += ' !text-green-500 '

    return (
        <Data {...props} className={cn}/>
    )
} 

function Min(props) {

    return (
        <Data {...props}/>
    )
}

function Max(props) {

    const {data} = props

    //if
    return (
        <Data {...props}/>
    )
}

const formatsIcons = {
    money: [Money, ""],
    up: [ArrowUpIcon, " text-green-400 "],
    down: [ArrowDownIcon, " text-orange-400"],
}

const aligns = {
    left:``,
    right:` justify-end `,
    center:` justify-center `,
}

function Data(props) {

    const tw = useTw()
    const {path, value, format, align, width, icon, onClick, className="", schema} = props
    const { events } = schema
    const [val] = useData(path, "Data" + path)

    const [fIcon, fClass] = (formatsIcons[format] || formatsIcons[icon]) || []

    const onClickEvent = onClick
        ? events[onClick]
        : null

    return (
        <div className={tw.data.root + tw.formats[format] + " "+ className}>

                { fIcon
                    && <div className={tw.data.iconContainer}>
                        {createElement(fIcon, {
                            className:tw.data.icon + " " + fClass,
                        })}
                    </div>
                }
                <div className={tw.data.def + aligns[align || format] + ` w-${width} `}
                    onClick={onClickEvent ? (e) => onClickEvent(e, props) : null}
                >
                    { formatValue(format, val) }
                </div>

        </div>
    )
}

function formatValue(f, v) {

    if(f === 'money')
        return decimal(v)
    else if(f === 'decimal')
        return decimal(v)
    //else if(f === 'date')
    //    return dateToString(v)
    else
        return v
}

function getType(format) {

    const f = {
        date:'date',
        password:'password',
    }

    return f[format] || 'text'
}

function DataEdit(props) {

    const tw = useTw()
    const {path, value, def, placeholder, format, align, width, icon, className, containerClassName, schema} = props
    const {events, type} = schema || {}
    const [val, setVal] = useData(path, "DataEdit" + path)

    const [fIcon, fClass] = (formatsIcons[icon || format]) || []

    if(val === undefined && value !== undefined)
        setVal(value)

    return (
        <div className={tw.data.edit + " " + containerClassName}>
            { fIcon
                && <div className={tw.data.iconContainer}>
                    {createElement(fIcon, {
                        className:tw.data.icon + " " + fClass,
                    })}
                </div>
            }
            <input
                type={getType(format)}
                value={val === undefined ? '' : formatValue(format, val)}
                className={tw.data.input + ` text-${align} w-${width} ` + (type === 'form' ? " bg-gray-100 ":"  ") + className}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
            />
        </div>
    )

    function onKeyUp(e) {

        if(e.keyCode === 13)
            e.target.blur()
    }

    function onBlur(e) {

        const value = e.target.value
        setVal(value)
        events.onChangeValue && events.onChangeValue(props, e)
    }

    function onChange(e) {

        const value = e.target.value

        setVal(value)
    }
}

function Color({value, label, className}) {
    const tw = useTw()
    const [state, setState] = useState(value)

    return (
        <div className={tw.color.root + tw.color.edit + value}/>
    )
}
function ColorEdit({value, label, className}) {

    const tw = useTw()
    const [color, setColor] = useState(value)
    const [open, setOpen] = useState(false)
    const colorList = ['slate','stone', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose', 'red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue']
    const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

    return (
        <div className={tw.color.root + tw.color.edit}>
            {!open && <SwatchIcon className={tw.color.icon}
                onClick={() => setOpen(!open)}
            />}
            <div className={tw.color.def}
                style={{
                    backgroundColor:color
                }}
            />
            { open && <ColorPicker/>}
        </div>
    )

    function ColorPicker() {

        return (
            <div className={tw.color.picker.root}>
                <div className={tw.color.picker.container}>
                    {shades.map( shade => 

                        <div key={shade} className={tw.color.picker.col}>
                            {colorList.map( color =>
                                <div
                                    key={color}
                                    style={{
                                        backgroundColor:colors[color][shade]
                                    }}
                                    className={`h-4 w-4`}
                                    onMouseOver={(e) => onMouseOver(colors[color][shade])}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    function onMouseOver(color) {

        setColor(color)
    }
}
function Image({value, className, src}) {
    const tw = useTw()

    return(
        <img className={tw.image.def + className} src={ src(value) } alt="" />
    )
}
function Rows({rows, ...props}) {

    return <Components mainClass="rows" fields={rows} {...props}/>
}
function Columns({columns, ...props}) {

    return <Components mainClass="columns" fields={columns} {...props}/>
}

function Components({mainClass, fields, data, tables, setData, index, stateRow, setStateRow, className, api, set, src}) {

    const tw = useTw()

    return (
        <div className={tw[mainClass].def +" "+ className}>
            {Object.keys(fields).map((it, ind) => {

                const col = fields[it]
                const cmp = set[col.edit === true ? "edit": stateRow][col.type]

                return (cmp &&
                    <div key={it} className={tw[mainClass].item +" "+ col.containerClassName}>
                        { createElement(cmp, {
                            key:it,
                            index,
                            id: data._id,

                            ...col,
                            set,
                            value: data[col.field] === undefined ? col.value : data[col.field],

                            src,
                            data,
                            setData,
                            tables,
                            stateRow,
                            setStateRow,
                            api,
                        })}
                    </div>
                )
            })}
        </div>
    )
}

function Set({field, value, tables, className}) {

    const tw = useTw()
    //const arr = value !="" && value.substring(1, value.length - 1).split(",")
    // perm[c].color
    const arr = value.split(',')
    const table = tables[field]

    return (
        <div className={tw.set.root + className}>
            {arr && arr.map(it => {

                const {_color, _code} = table[it]

                return(
                    <div key={it} className={tw.set.item}
                        style={{backgroundColor:_color}}
                    >
                        { _code }
                    </div>
                )
            })}
        </div>
    )
}

function DeleteButton({id, api, onDelete}) {
    const tw = useTw()

    return (
        <div className={tw.button.delete.root}>
            <TrashIcon
                className={tw.button.def + tw.button.delete.trash}
                onClick={() => api.delete(id)}
            />
        </div>
    )
}

function LockButton({onUnlock, onDelete}) {
    const tw = useTw()

    return (
        <div className={tw.button.lock.root}>
            <LockClosedIcon
                className={tw.button.def + tw.button.lock.def}
            />
            <LockOpenIcon
                className={tw.button.def + tw.button.lock.unlock}
                onClick={onUnlock}
            />
            <TrashIcon
                className={tw.button.def + tw.button.lock.trash}
                onClick={onDelete}
            />
        </div>
    )
}

function UpdateButton({setStateRow}) {
    const tw = useTw()

    return (
        <PencilIcon
            className={tw.button.def + tw.button.edit}
            onClick={() => setStateRow("edit")}
        />
    )
}
function OkButton({id, index, data, api, onClick, setStateRow, setChildRow, className}){
    const tw = useTw()

    return (
        <CheckIcon className={tw.button.def + tw.button.ok}
            onClick={e => onHandlerClick()}
        />
    )

    function onHandlerClick() {

        if(onClick) {
            onClick(data)
        } else {
            if(id === undefined)
                api.insert(data, index, row => {
                    setStateRow('read')
                    setChildRow(true)
                })
            else
                api.update(id, data, index, row =>
                    setStateRow('read')
                )
        }
    }
}

function CancelButton({onClick, className}){
    const tw = useTw()

    return (
        <XMarkIcon className={tw.button.def + tw.button.cancel + className}
            onClick={e => onClick()}
        />
    )
}
function MoreButton({onClick, data, className}){
    const tw = useTw()

    return (<div className={tw.button.more.root}>
            <EllipsisVerticalIcon className={tw.button.more.def}
                onClick={e => onClick()}
            />
            <div className={tw.button.more.menu}>
                <div>1</div>
                <div>2</div>
                <div className={tw.button.more.item}>
                    <PlusIcon className={tw.button.more.icon}/><span>{ data._updated}</span>
                </div>
                <div className={tw.button.more.item}>
                    <PencilIcon className={tw.button.more.icon}/><span>{ data._inserted}</span>
                </div>
            </div>
        </div>
    )
}

const buttons = {
    update: UpdateButton,
    delete: DeleteButton,
    lock:   LockButton,
    ok:     OkButton,
    cancel: CancelButton,
    more:   MoreButton,
}

function Button({value, ...props}) {

    const tw = useTw()

    return buttons[value] && createElement(buttons[value], {
        className: tw.button.def + tw.button[value],
        ...props,
    })
}

const buttonsEdit = {
    update: OkButton,
    delete: null,
    lock:   LockButton,
    more:   MoreButton,
}

function ButtonEdit({value, ...props}) {

    const tw = useTw()

    return buttonsEdit[value] && createElement(buttonsEdit[value], {
        className: tw.button.def + tw.button[value],
        ...props,
    })
}

function Check({}){

    const tw = useTw()

    return(
        <div className={tw.check.root}>

        </div>
    )
}
function CheckEdit({}){

    const tw = useTw()

    return(
        <div className={tw.check.edit}>

        </div>
    )
}
function Status({value}){

    const tw = useTw()

    return(
        value === 0
        ? <LockClosedIcon className={tw.status.def}/>
        : null
    )
}
function StatusEdit(props){

    const tw = useTw()

    const  {id, value, schema } = props
    const { events } = schema

    return(
        id > 0 && (
            value === 0
                ?   <LockClosedIcon className={tw.status.close}  onClick={e => onClick(1)}/>
                :   <span className={tw.status.mask} onClick={e => onClick(0)}>
                        <LockOpenIcon className={tw.status.open}/>
                    </span>
        )
    )

    function onClick(e) {

        events.setStatus(props, e)
        //api.put({_status}, id, setData, setError)
    }
}

function StatusRemove(props){

    const { id, schema } = props
    const { events, api } = schema

    const tw = useTw()

    return(
        id > 0 && <TrashIcon className={tw.status.remove}  onClick={onClick}/>
    )

    function onClick(e) {

        events.onDelete(props, e)
    }
}

function OptionsEdit(props) {

    const { field, path, options, value, schema } = props
    const { events } = schema
    const [val, setVal] = useData(path, "DataEdit" + path)

    if(val === undefined && value !== undefined)
        setVal(value)

    return(
        <select onChange={onChange} defaultValue={val} className="block p-2 text-sm  text-gray-900 bg-gray-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black/20 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {
                options && Object.entries(options).map(([k, v]) => {

                    let value = v.value || v

                    return(
                        <option key={k} value={k}>
                            {v}
                        </option>
                    )
                })
            }
        </select>
    )

    function onChange(e) {

        const value = e.target.value

        //setDataContext(path, value)
        setVal(value)

        events.onChangeValue && events.onChangeValue(props, e)
        //events.fields[field].onChange && events.fields[field].onChange(props, e)
    }
}

const allSet = {
    read: {
        check: Check,
        data: Data,
        color: Color,
        image: Image,
        columns: Columns,
        rows: Rows,
        button: Button,
        set: Set,
        status: Status,
        min:Min,
        max:Max,
        existencia:Existencia,
    },
    edit: {
        check:CheckEdit,
        data: DataEdit,
        options: OptionsEdit,
        color: ColorEdit,
        image: Image,
        columns: Columns,
        rows: Rows,
        button: ButtonEdit,
        set: Set,
        status: StatusEdit,
        remove: StatusRemove,
        min:Min,
        max:Max,
        existencia:Existencia,
    }
}

export default allSet

/*
function Component({row, col, className="", cmp}) {

    const value = row[col.field]
    const cn = className +" "+ col.className || ''

    //const cmp = cmp[col.type]

    //console.log(col.field, value, cmp)

    return cmp !== undefined && createElement(cmp, {
            ...col,
            className: cn || '',
            value
        }
    )

    //content[col.type] && content[col.type]()
}
*/