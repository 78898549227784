import { createElement, useEffect, useState } from 'react'
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import useTheme from '../../context/useTheme'
import useData from '../../context/useData'
import { firstUpperCase } from '../../libs/utils'
import setError from '../../context/setError'
import updater from '../../context/updater'
import getData from '../../context/getData'

const useTw = () => useTheme(theme => ({
    root:`
    `,
    table:`
        min-w-full
        divide-y divide-black/10
        //dark:divide-black/90
    `,
    header:`
        px-6 py-2
        text-left
        font-thin
        text-sm
        bg-cyan-600
        dark:bg-cyan-800
        text-white
        dark:text-black
    `,
    body:`
        min-w-max    
        divide-y
        dark:divide-black/40
             divide-black/10
    `,
    row:`
        relative
        min-w-max
        //border-y
        //dark:border-stone-500
             //border-stone-50
        dark:hover:bg-black/20
             hover:bg-stone-50
        hover:border-stone-200
        group
        cursor-default
    `,
    data:`
        py-2.5 px-4
        group
    `,
    emptyData:`
        min-w-max
        py-4 px-6
        text-center
    `,
    emptyLabel:`
        flex flex-row items-center justify-center
        text-gray-500
    `,
    addIcon:`
        w-6 h-6
        p-1 m-2
        rounded
        bg-gray-50
        border
    `,
    containerAcceptButton:`
        relative 
        w-0 max-w-0
        //bg-cyan-400
    `,
    acceptButtonRow:`
        absolute top-1/2 right-2 transform -translate-y-1/2
        p-1
        w-9 h-9
        //min-h-full
        bg-green-600
        rounded-full
        text-white
    `,
    // absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    icon:{
        def:`
            h-8 w-8
            p-1
            rounded-lg
            bg-white
            text-gray-300
        `,
    },
    errorData:`
        text-red-400
    `,
}), "tw")

export default function Body({schema}) {

    const tw = useTw()
    const {data, name, header, editable=false, blankRow=false, row, columns={}, src, set, api, events} = schema
    const {count, rows} = data || {}
    const pathRow = `${name}/table/data/rows`

    return ( 
        <div className={tw.root}>
            <table className={tw.table}>        
                <Header/>
                <tbody className={tw.body}>
                    <Rows schema={schema}/>
                </tbody>
            </table>
        </div>
    )

    // Header
    function Header({}) {
        const cmp = set.read

        return ( header !== false &&
            <thead>
                <tr>
                    { Object.keys(columns).map( it => {

                        const col = columns[it]
                        //console.log(it, col)
                        return (
                            !col.hidden
                            && <th key={it} className={tw.header + ` text-${col.align} `}>
                                { col.label === undefined
                                    ? firstUpperCase(col.field)
                                    : col.label
                                }
                            </th>
                        )
                    })}
                </tr>
            </thead>
        )
    }
    // Row Content
    function Rows({schema}) {

        return ((count > 0 || blankRow) &&
            <Row index={0} schema={schema} />
        )
    }

    function Row(props) {

        var {index, schema} = props
        const {events} = schema

        const updateIdRow = updater(`${name}/table/current_id`)
        var [row={}] = useData(`${pathRow}/${index}`, `Row_`+ pathRow + index)
        const [state, setState] = useState(editable ? 'edit':'read')
        const list = Object.keys(columns)
        const cmp = set[state]
        const {_id, __editing} = row

        /*
        if(__editing) {

            var sw = true

            list.forEach(it =>{

                if(row[it] === undefined && columns[it].hidden !== true)
                    sw = false

            })

            sw && insert()
        }
        */

        return (<>
            <tr key={index} className={tw.row} onClick={e => onClickRow(_id)}>
                { list.map( (it, ind) => {

                    const col = columns[it]
                    const component = cmp[col.type] || cmp.data
                    const path = `${pathRow}/${index}/${col.field}`

                    return (
                        ((component && !col.hidden) && col.field !== undefined)
                        && <td key={ind} className={tw.data +" "+ col.containerClassName} >
                            {createElement(component, {
                                index,
                                id: row._id,

                                ...col,

                                value: row[col.field] === undefined ? col.value : row[col.field],
                                path,
                                row,

                                schema,
                            })}
                        </td>
                    )
                })}
                {
                    <BlankRow index={index} schema={schema}/>
                }
            </tr>
            {
                ++index < rows.length + (blankRow ? 1 : 0)
                    && <Row index={index} schema={schema}/>
            }
        </>)
        // La bondad Coca Cola.

        function BlankRow(props) {

            const { index } = props
            const [editing] = useData(`${pathRow}/${index}/__editing`)

            return(
                (editing) &&
                <td className={tw.containerAcceptButton}>
                    <CheckIcon className={tw.acceptButtonRow} onClick={() => events.insert(props)}/>
                </td>
            )
        }

        function onClickRow(id) {
            updateIdRow(id)

            const { onClick } = schema.row || {}

            if(onClick)
                events[onClick[0]](onClick[1], row._id)
        }
    }

    // Rows states
    function EmptyRow({children}){
    
        return(
            <tr className={tw.row}>
                <td className={tw.emptyData}
                    colSpan={columns.length}
                >
                    <div className={tw.emptyLabel}>
                        { children}
                    </div>
                </td>
            </tr>
        )
    }
    function WaitingDataLabel() {

        return (
            <EmptyRow>
                <span>Cargando datos...</span>
            </EmptyRow>
        )
    }
    function EmptyDataLabel() {

        return (
            <EmptyRow>
                <span>Da clic en</span>
                <PlusIcon className={tw.addIcon}/>
                <span>para agragar una fila.</span>
            </EmptyRow>
        )
    }
    function ErrorDataLabel() {

        return (
            <EmptyRow>
                <span className={tw.errorData}>Error en la recuperacion de los datos. Actualiza tu navegador.</span>
            </EmptyRow>
        )
    }
}