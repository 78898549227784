import { ctx } from "../../data/context"

const common = {
    error:'#aa0000',
    ok:"#00cc00",
}

const obj = {
    //ctx,
    column: (alignItems="center", justifyContent="center") => ({
        display:'flex',
        flexDirection:'column',
        justifyContent,
        alignItems,
    }),
    row: (justifyContent=undefined, alignItems="center") => ({
        display:'flex',
        justifyContent,
        alignItems,
    }),
    flex: (justifyContent=undefined, alignItems=undefined) => (

    obj[ctx.direction](justifyContent, alignItems)
    ),
    avatar: (size="sm", height=1) => ({
        minWidth:ctx.avatarSize[size] + "px",
        minHeight:ctx.avatarSize[size] * height + "px",
        maxWidth:ctx.avatarSize[size] + "px",
        maxHeight:ctx.avatarSize[size] * height + "px",
        borderRadius:ctx.avatarSize[size],
        background: 'none',
        margin: ctx.xsSpacing,
    }),
}



const getMode = (mode) => ({
    ...obj ,
    ...(mode === 'light'
        ? {
            palette: {
                mode:'light',
                common,
                background:{
                    paper:'#ffffff',
                    default:'#e0e0e0', //'#73d0e2', //'#e0e0e0', //'#1686a2',
                },
            },
        } : {
            palette: {
                mode:'dark',
                common,
                background:{
                    paper: '#121212',
                    default: '#0e0e0e'
                }
            },
        }
    )
})

export default function useTheme(f, type="sx") {

    const p = getMode(ctx.data.config.theme.mode)

    return f(p)
}