import { useEffect } from 'react'
import Card from './Card'
import Header from './table/Header'
import Body from './table/Body'
import Footer from './table/Footer'
import rest from '../libs/useRest'
import useData from '../context/useData'
import setError from '../context/setError'
import app from '../../data/app'
import { event, mapProperties } from '../libs/utils'
import updater from '../context/updater'

export default function Table({route, name=route, icon, label, schema, map, card, query={}, src}) {

    const dataPath = `${name}/table`
    const [dataSchema={ready:false, reload:true, page:0, filter:undefined}, setDataSchema] = useData(dataPath, 'Table')
    const [api] = schema ? [] : rest(`data/${name}`, data => setDataSchema({data}), setError)
    const { ready, reload, filter, page, className={} } = dataSchema

    function refresh(query) {

        const getEvents = (data) => {

            const { rows={} } = data

            const fields = {}

            Object.entries(rows).map(([key, props]) => {
                fields[key] = {
                    update: value => {
                        //updater(`${dataPath}/data/${key}`)(value)
                    }
                }
            })

            return {
                ...app.events,
                fields,
                setData: d => {
                    setDataSchema(d)
                },
                reload: d => {
                    setDataSchema({reload:!reload, ...d})
                },
                page: (page) => {

                    setDataSchema({page})
                },
                insert: (props) => {

                    const {index, schema}  = props
                    const {data, columns, blankRow, events} = schema

                    const post = data.rows[index]

                    delete post.__editing
        
                    Object.entries(columns).forEach(([key, props]) => {
                      
                        if(props.def !== undefined && post[key] === undefined) {
                            post[key] = props.def
                        }
        
                    })

                    api.post(post, row => {
        
                        console.log(row)
                        if(blankRow)
                            events.reload()
                    }, setError)
                },
                onChangeValue: (props, e) => {

                    const {id, index, field, row, schema} = props
                    const {saveOnChange, events} = schema

                    //console.log({id, index, field, data, saveOnChange, events})
                    if(id > 0) {

                        if(saveOnChange) {
                            api.put({[field]: row[field]}, id, res => {
            
                                //setData(row)
                                //setVal(row[field])
                                updater(`${dataPath}/data/rows/${index}/__editing`)(false)
                            }, setError)
                        } else {
                            updater(`${dataPath}/data/rows/${index}/__editing`)(true)
                        }

                    } else {

                        updater(`${dataPath}/data/rows/${index}/__editing`)(true)
                        events.onBlur && events.onBlur(props)
                        events.fields[field].onBlur && events.fields[field].onBlur(props)
                    }
                },
                setStatus: (props, e) => {

                    const {id, row, schema} = props
                    const {api, events} = schema

                    api.put({_status:row._status ? 0:1}, id, r => {

                        //console.log("resp", r)
                        events.reload()

                    }, setError)
                },
                onDelete: (props, e) => {

                    const {id, schema} = props
                    const {api, events} = schema

                    api.del({}, id, r => {

                        console.log("resp", r)
                        events.reload()
            
                    }, setError)
                }
            }
        }

        api.get(query, "table", res => {

            var data = {
                ...res,
                events:getEvents(res),
                filter,
                page,
                set:app.set,
                src,
                api,
                reload,
                ready:true
            }

            data = map ? mapProperties(data, map) : data

            setDataSchema(data)

            const schema = map ? mapProperties(data, map) : data

            setDataSchema(schema)

            event(schema.events.onGet, schema)

        }, setError)

    }

    useEffect(() => {
        schema
            ? setDataSchema(schema)
            : refresh({filter, page, ...query})
    }, [reload, page])


    const cardClassName = card === false && " bg-white/0 dark:bg-white/0 "

    //console.log("table", dataSchema)

    return ( ready &&
        <Card className={cardClassName + ` ${className.card}`}>
            <Header schema={dataSchema} icon={icon} label={label}/>
            <Body schema={dataSchema}/>
            <Footer schema={dataSchema}/>
        </Card>
    )
}