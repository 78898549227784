const currency = (num, locale = 'en-US', currency = 'USD', minimumFractionDigits = 2) => {

	if (isNaN(num) || num === null ) {
		return num;
	}

	return num.toLocaleString(locale, {style: 'currency', currency, minimumFractionDigits});
}

const intCurrency = (num, currency = 'USD', locale = 'en-US') => {

	if (isNaN(num) || num === null) {
		return num;
	}

	return num.toLocaleString(locale, { currency, maximumFractionDigits:0});
}

const fullCurrency = (rate) => (

	currency(rate, undefined, undefined, 0)
)

const decimal = (num=0, maximumFractionDigits=2) => (

	num.toLocaleString('en-US', {maximumFractionDigits, minimumFractionDigits:maximumFractionDigits})
)

const truncate = (num, maximumFractionDigits=2) => (

	Math.trunc(num * Math.pow(10, maximumFractionDigits)) / Math.pow(10, maximumFractionDigits)
)

const round = (num, digits=2) => (

	Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits)
)

const fillDecimals = (number, length) => {

	number = Math.round(number * 100000000) / 100000000;

	function pad(input, length, padding) { 
		var str = input + "";

		return (length <= str.length) ? str : pad(str + padding, length, padding);
	}

	var str        = number+"";
	var dot        = str.lastIndexOf('.');
	var isDecimal  = dot != -1;
	var integer    = isDecimal ? str.substring(0, dot) : str;
	var decimals   = isDecimal ? str.substring(dot+1)  : "";

	decimals       = pad(decimals, length, 0);

	return integer + '.' + decimals;
}

const isoDate = (date) => {
    date = date || new Date();
    return date.toISOString().split('T');
}

Date.prototype.monthDays= function(){
    var d = new Date(this.getFullYear(), this.getMonth()+1, 0)

	return d.getDate()
}

function firstUpperCase(s="") {

    return s.charAt(0).toUpperCase() + s.slice(1);
}

function dateToString(date) {

	const[year, month, day] = date.substring(0,10).split('-')
	const d = new Date()
	const y = d.getFullYear()
	var res = ""
	const fullMonths = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre'
	]
	const minMonths = [
		'Ene',
		'Feb',
		'Mar',
		'Abr',
		'May',
		'Jun',
		'Jul',
		'Ago',
		'Sep',
		'Oct',
		'Nov',
		'Dic'
	]

	res = `${parseInt(day)
	} ${minMonths[parseInt(month) -1]}`

	if(year != y)
		res += ` ${year}`

	return res
}

function mapProperties(obj, obj2) {

	obj2 && Object.entries(obj2).forEach(([key, value]) => {
		if(typeof value === 'object') {

			if(obj[key] === undefined)
				obj[key] = {}

			mapProperties(obj[key], value)

		} else
			obj[key] = value
	})

	return obj
}

function event(func, props, res) {

	//alert(func && func.name)
	if(func) {
		if(typeof func === 'function')
			return func(props, res) ?? true
		else
			return props.schema.events[func[0]](func[1], res) ?? true

	} else {

		return func
	}
}

export {currency, intCurrency, fullCurrency, decimal, truncate, round, fillDecimals, isoDate, dateToString, firstUpperCase, mapProperties, event};