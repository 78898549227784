import useTheme from "../context/useTheme"

const useTw = () => useTheme(theme => ({
    root:`
        p-1 px-8
        text-white
        rounded-full
        cursor-default
    `,
    /*
    root:`
        relative
        flex
        w-full
        justify-center
        rounded-md
        border
        border-transparent
        bg-indigo-600
        py-1
        px-4
        text-sm
        font-medium
        text-white
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2
        focus:ring-indigo-500
        focus:ring-offset-2
        cursor-default
    `,
    */
    secondary:`
        bg-indigo-600
        dark:bg-indigo-600
        hover:bg-indigo-500
        active:bg-indigo-700
    `,
    accept:`
        bg-cyan-600
        dark:bg-cyan-600
        hover:bg-cyan-400
        active:bg-cyan-700
    `,
    cancel:`
        bg-red-600
        dark:bg-red-600
        hover:bg-red-400
        active:bg-red-700
    `
}), "tw")

export default function Button({label, type="accept", className, onClick, icon}) {

    const tw = useTw()

    return (
        <span className={tw.root + " "+ tw[type] +" " + tw.labelAdd + " " + className} onClick={onClick}>
            {label}
            {icon}
        </span>
    )
}