import useTheme from '../../context/useTheme'

const useTw = () => useTheme(theme => ({
    root:`
        flex justify-center
        rounded-b
        bg-slate-100
        dark:bg-black/20
    `,
    counter:`
    `,
    pages:`
        flex justify-center items-center
        min-w-full
        px-5
    `,
    page:`
        flex justify-center items-center 
        w-10 h-10
        text-sm 
        text-black
        dark:text-white font-medium
        rounded-full
        cursor-pointer
        hover:bg-cyan-800
    `,
    current:`
        dark:bg-cyan-900
        bg-cyan-600
        !text-white
    `,
    arrow:`
    `,
}), "tw")

export default function Footer({schema}) {

    const tw = useTw()

    const {data={}, events} = schema
    var {count, limit, page} = data

    if(limit === 0)
        limit = count

    const labelResults = null /* count > 0
        ? count > 1
            ? count > limit
                ? `${count} a 20 de ${count} Registros`
                : `${count} Registros`
            : `1 Registro`
        : ""
    */

    const visible = count > limit
    const pages = Math.ceil(count / limit)

    return (
        visible && <div className={tw.root}>
            <div className={tw.counter}>
                { labelResults }
            </div>
            <div className={tw.pages}>

                <nav className="flex items-center space-x-2">
                    <a onClick={() => events.page(0)} className="text-gray-400 hover:text-blue-600 p-4 inline-flex items-center gap-2 font-medium rounded-md" href="#">
                        <span aria-hidden="true">«</span>
                        <span className="sr-only">Anterior</span>
                    </a>
                    {
                        (new Array(pages)).fill(1).map((it, ind) =>
                            <a key={ind} className={tw.page + (ind === page && tw.current)} onClick={() => events.page(ind)} aria-current="page">{ind+1}</a>
                        )
                    }
                    <a onClick={() => events.page(pages-1)} className="text-gray-400 hover:text-blue-600 p-4 inline-flex items-center gap-2 font-medium rounded-md" href="#">
                        <span className="sr-only">Siguiente</span>
                        <span aria-hidden="true" >»</span>
                    </a>
                </nav>
            </div>
        </div>
    )
}
/*
<div>
{ count } registros
</div>
*/