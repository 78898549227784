import { createElement } from "react"
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline"
import useTheme from "../../context/useTheme"
import { setLocation } from "../../libs/location"

const useTw = () => useTheme(theme => ({
    root:`
        absolute
        left-0 top-0
        flex items-center justify-start
        min-w-full h-16
        px-6
        bg-white/40
        //bg-slate-300
        //border-b
        //border-slate-300
        //shadow

        hover:bg-gray-500/50
    `,
    title:`
        font-bold
        text-lg
        text-slate-600
        dark:text-black
    `,
    tools:{
        root:`
        `,
        icon:`
            w-9 h-9
            p-1
            rounded-full
            dark:bg-white/10
                 bg-gray-200

            dark:hover:bg-black
                hover:bg-white
        `,
        light:`
            text-yellow-400
        `,
        dark:`
            text-purple-600
        `,
    },
    button:`
        relative
        inline-flex
        items-center
        py-1.5
        px-2
        rounded-full
        transition-colors
        duration-300
        focus-visible:ring-2
        focus-visible:ring-offset-2
        focus-visible:ring-offset-white
        focus:outline-none
        bg-slate-700
        hover:text-slate-400
        focus-visible:ring-slate-500
    `,
    icon:`
        w-7 h-7    
        mr-1.5
    `,
}), "tw")

export default function Bar(props) {

    const tw = useTw()

    const { target, app } = props
    const { icon, components } = target
    const title = target.label || props.name

    if(icon && icon [0]) {
        var component = icon[0]
        var className = icon[1]
    }

    return (
        <div className={tw.root}>
            { component && createElement(app.components[component], {
                className: tw.icon + className  
            })}
            <Title/>
            {false && <Tools/> }
        </div>
    )

    function Title() {
        return(
            <div className={tw.title}>
                {title.toUpperCase()}
            </div>
        )
    } 
    function Tools(){
        //const [mode, setMode] = useData('config/theme/mode')

        const theme = localStorage.theme
        
        return(
            <div className={tw.tools.root}>
                { theme
                    ?   <SunIcon className={tw.tools.icon + tw.tools.light}
                            onClick={() => mode()}
                        />
                    : <MoonIcon className={tw.tools.icon + tw.tools.dark}
                        onClick={() => mode()}
                    />
                }
            </div>
        )
    }

    function mode() {

        if (localStorage.theme === 'dark') {
            console.log(1)
            document.documentElement.classList.remove('dark')
            localStorage.removeItem('theme')
        } else {
            console.log(2)
            document.documentElement.classList.add('dark')
            localStorage.theme = 'dark'
        }

        setLocation()

        /*
        console.log(localStorage.theme)
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            console.log(1)
            document.documentElement.classList.remove('dark')
            localStorage.theme = 'dark'

        } else {
            console.log(2)
            document.documentElement.classList.add('dark')
            localStorage.removeItem('theme')
        }
        */
    }
  }

  /*

  // On page load or when changing themes, best to add inline in `head` to avoid FOUC


// Whenever the user explicitly chooses light mode
localStorage.theme = 'light'

// Whenever the user explicitly chooses dark mode
localStorage.theme = 'dark'

// Whenever the user explicitly chooses to respect the OS preference
localStorage.removeItem('theme')

                  <button className={tw.button} id="headlessui-switch-5" role="switch" type="button" tabIndex="0" aria-checked="true">

                  <span className="sr-only">
                    Enable dark mode
                </span>
                    <svg width="24" height="24" fill="none" aria-hidden="true" className="transform transition-transform scale-100 duration-300">
                        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-darkreader-inline-fill="" data-darkreader-inline-stroke="" style="--darkreader-inline-fill:currentColor; --darkreader-inline-stroke:currentColor;">
                        </path>
                        <path d="M12 4v1M18 6l-1 1M20 12h-1M18 18l-1-1M12 19v1M7 17l-1 1M5 12H4M7 7 6 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-darkreader-inline-stroke="" style="--darkreader-inline-stroke:currentColor;"></path></svg><svg width="24" height="24" fill="none" aria-hidden="true" className="ml-3.5 transform transition-transform scale-0 duration-500"><path d="M18 15.63c-.977.52-1.945.481-3.13.481A6.981 6.981 0 0 1 7.89 9.13c0-1.185-.04-2.153.481-3.13C6.166 7.174 5 9.347 5 12.018A6.981 6.981 0 0 0 11.982 19c2.67 0 4.844-1.166 6.018-3.37ZM16 5c0 2.08-.96 4-3 4 2.04 0 3 .92 3 3 0-2.08.96-3 3-3-2.04 0-3-1.92-3-4Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-darkreader-inline-fill="" data-darkreader-inline-stroke="" style="--darkreader-inline-fill:currentColor; --darkreader-inline-stroke:currentColor;">
                        </path>
                    </svg>
                <span className="absolute top-0.5 left-0.5 bg-white w-8 h-8 rounded-full flex items-center justify-center transition duration-500 transform translate-x-[2.625rem]">
                    <svg width="24" height="24" fill="none" aria-hidden="true" className="flex-none transition duration-500 transform text-cyan-500 opacity-0 scale-0">
                        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-darkreader-inline-fill="" data-darkreader-inline-stroke="" style="--darkreader-inline-fill:currentColor; --darkreader-inline-stroke:currentColor;">
                        </path>
                        <path d="M12 4v1M18 6l-1 1M20 12h-1M18 18l-1-1M12 19v1M7 17l-1 1M5 12H4M7 7 6 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-darkreader-inline-stroke="" style="--darkreader-inline-stroke:currentColor;">
                        </path>
                    </svg>
                    <svg width="24" height="24" fill="none" aria-hidden="true" className="flex-none -ml-6 transition duration-500 transform text-slate-700 opacity-100 scale-100">
                        <path d="M18 15.63c-.977.52-1.945.481-3.13.481A6.981 6.981 0 0 1 7.89 9.13c0-1.185-.04-2.153.481-3.13C6.166 7.174 5 9.347 5 12.018A6.981 6.981 0 0 0 11.982 19c2.67 0 4.844-1.166 6.018-3.37ZM16 5c0 2.08-.96 4-3 4 2.04 0 3 .92 3 3 0-2.08.96-3 3-3-2.04 0-3-1.92-3-4Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-darkreader-inline-fill="" data-darkreader-inline-stroke="" style="--darkreader-inline-fill:currentColor; --darkreader-inline-stroke:currentColor;">
                        </path>
                    </svg>
                </span>
                </button>
  */