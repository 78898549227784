import { useEffect } from 'react'
import Card from './Card'
import Header from './form/Header'
import Body from './form/Body'
import Footer from './form/Footer'
import useData from '../context/useData'
import rest from '../libs/useRest'
import setError from '../context/setError'
import app from '../../data/app'
import { mapProperties } from '../libs/utils'
import updater from '../context/updater'

export default function Form({name, icon, label, schema, card=true, footer, map, src, id, query={}}) {

    const dataPath = `${name}/form`
    const [dataSchema={reload:false, ready:false, type:'form'}, setDataSchema] = useData(dataPath, 'Form')
    const [api] = schema ? [] : rest(`data/${name}/${id}`, data => setDataSchema({data}), setError)
    const { ready, reload, className={} } = dataSchema

    function refresh(query) {

        const getEvents = (data) => {

            var { rows={} } = data
            const fields = {}
            rows = {...(map && map.rows), ...rows}

            Object.entries(rows).map(([key, props]) => {
                fields[key] = {
                    update: value => {
                        updater(`${name}/form/data/${key}`)(value)
                    }
                }
            })

            return {
                ...app.events,
                fields,
                reload:() => {
                    setDataSchema({reload:!reload})
                },
                /*setInputData: (field, value) => {
                    console.log("DDDD",data)
                    data.data[field] = value
                    setDataSchema(data)
                },
                */
                setData: (data, cbk) => {

                    setDataSchema({...data, callback:cbk})
                },
                onChangeValue: (props, e) => {

                    const { field, schema } = props
                    const { events } = schema

                    updater(`${dataPath}/data/__editing`)(true)
                    events.onBlur && events.onBlur(props)
                    events.fields[field].onBlur && events.fields[field].onBlur(props)
                },
                ...data.events,
            }
        }

        api.get(query, `form`, res => {

            var data = {
                ...res,
                events:getEvents(res),
                set:app.set,
                src,
                api,
                reload,
                ready:true,
                type:'form',
            }

            //console.log("MAP", data, map)
            const schema = map ? mapProperties(data, map) : data

            setDataSchema(schema)

            if(schema.events.onGet) {
                schema.events.onGet(schema)
            }

        }, setError)
    }

    useEffect(() => {
        schema
        ? setDataSchema(schema)
        : refresh(query) //events.get({...query})
    }, [reload])

    const cardClassName = card === false && " bg-white/0 dark:bg-white/0 "

    return (
        ready &&
        <Card className={cardClassName + ` ${className.card}`}>
            <Header schema={dataSchema} setData={setDataSchema} id={id} icon={icon} label={label}/>
            <Body schema={dataSchema} setData={setDataSchema} id={id}/>
            <Footer schema={dataSchema} setData={setDataSchema} footer={footer} id={id}/>
        </Card>
    )
}